import {Col, Image} from "react-bootstrap";
import {getUrl} from "../../helper/function";
import Url from "../links";
import Cols from "../grid/col";
import {Setting} from "../../helper/setting";

function Box2(prop:any){
    return(
        <Cols total={prop.total}>
            <Url href={getUrl(prop.data)} >
                <div className={"mbox "}>
                    <div><Image srcSet={Setting.imagePrefix + prop.data.imageUrl}  style={{height:120}} /></div>
                    <p className={"py-1 w-100 mb-1 mt-3 btn btn2  bg-gradient"}>{prop.data.name}</p>
                </div>
            </Url>
        </Cols>
    )
}

export default Box2
