import {useTranslation} from "react-i18next";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import React from "react";

function Loading(prop:any) {
    const t = useTranslation()
    return(
        <Container className={"h-100"}>
        <Row className={"align-items-center justify-content-center h-100"}>
            <Col>
                <div className={"text-center"}><Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"}/></div>
            </Col>
        </Row>
        </Container>
    )
}
export default Loading
