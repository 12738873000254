import React, {useEffect,  useState} from "react";
import {Col, Container, Form, Image, Row, Spinner, Table} from "react-bootstrap";
import {  Setting} from "../../helper/setting";
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import axios from "axios";

function Telephone(){
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchtxt, setSearchtxt] = useState('');

    const columns = [
        {
            sortable: true,
            name: t.t("fullname"),
            selector: (row : any) => row.empName,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("position"),
            cell: (row : any) => <div>{row.empPosition}</div>,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("phone"),
            cell: (row : any) => <>{row.phone != '' ? <a href={"tel:"+row.phone}>{row.phone}</a> : "" }</>,
            style:{
                fontSize:"13px"
            }
        },{
            sortable: true,
            name: t.t("email"),
            cell: (row : any) => <>{row.email != '' ? <a href={"mailto:"+row.email}>{row.email}</a> : "" }</>,
            style:{
                fontSize:"13px"
            }
        }

    ]
    const SearchCases = (e:any) => {
        e.preventDefault()
        if(e.currentTarget.value !== searchtxt){
            setSearchtxt(e.currentTarget.value)
        }
    }
    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(Setting.api + 'PublicTelephoneCms/GetAll/GetAll'+ (searchtxt != "" ? "?search="+searchtxt : ""),
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);

        }).catch(function (error) {

        });
    };




    useEffect(() => {

        fetchDta(1);
    }, [localStorage.getItem("lang") ,searchtxt]);


    return(
        <Container className={"my-2"}>
            <Row>
                <Col>
                    <div style={{backgroundColor:"#f8f8f8"}} className={"py-4"}>
                        <Row className={"justify-content-center"}>
                            <Col xl={6}>
                                <Image srcSet={Setting.sysUrl + "images/svg/search2.svg"} className={"position-absolute mt-1 me-1 float-end"} width={30}  />
                                <Form.Control className={"border-0 pe-5 searchInput shadow-md"} onBlur={(e:any)=>{SearchCases(e)}} type={"text"} placeholder={t.t("phoneSearch")} />
                            </Col>
                        </Row>
                    </div>
                    <div className={"my-4  p-3 bg-white"}>
                        <div  className={"color4"}>
                            <DataTable
                                noDataComponent={t.t("noData")}
                                progressPending={loading}
                                progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={t.t("pleaseWait")} />}
                                columns={columns}
                                data={data}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Telephone
