import {Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Setting} from "../../../helper/setting";
import {useTranslation} from "react-i18next";

function AllTenders(){
    const t= useTranslation()

    return(
        <div className={"h-100"}>
            <Container className={"h-100"}>
                <Row  className={"align-items-center justify-content-center h-100"}>
                    <Col lg={10} md={12}>
                        <Row className={"align-items-center  "}>
                            <Col md={12} className={"text-center my-4"} ><h1 style={{color:"#1b465c"}}>{t.t("tmp.text56")}</h1></Col>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Link to={ "/tenders/31" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/b1.svg"} style={{height:100}} /></div>
                                        <p className={"pb-0 pt-0 mt-3 btn btn-outline-dark mb-1"}>{t.t("tmp.text57")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Link to={ "/tenders/30" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/b2.svg"} style={{height:100}} /></div>
                                        <p className={"pb-0 pt-0 mt-3 btn btn-outline-dark mb-1"}>{t.t("tmp.text58")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Link to={ "/tenders/32" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/b3.svg"} style={{height:100}} /></div>
                                        <p className={"pb-0 pt-0 mt-3  btn btn-outline-dark mb-1"}>{t.t("tmp.text59")}</p>
                                    </div>
                                </Link>
                            </Col>




                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default AllTenders
