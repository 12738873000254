
    import {Col, Container, Row} from "react-bootstrap";
    import React, {useEffect, useState} from "react";
    import Moment from "moment";
    import Calendar from "react-calendar";
    import 'react-calendar/dist/Calendar.css'
    import { GetDay, GetMonth, Setting} from "../../helper/setting";
    import {Link} from "react-router-dom";
    import axios from "axios";
    import {useTranslation} from "react-i18next";

    function Calenders(){
        const t = useTranslation()
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState([]);
        const [totalRows, setTotalRows] = useState(0);

        const [value, onChange] = useState(new Date());
        const [result, setResult] = useState(null);

        const days = [t.t("sun"),t.t("mon"),t.t("tue"),t.t("wed"),t.t("thu"),t.t("fri"),t.t("sat")]
        const month = [t.t('jan'),t.t('feb'),t.t('mar'),t.t('apr'),t.t('may'),t.t('jun'),t.t('jul'),t.t('aug'),t.t('sep'),t.t('oct'),t.t('nov'),t.t('dec')]


        const fetchDta = async (month) => {
            console.log(month)
            var currentDate = ""
            var futureMonth = ""

            if(month == ""){
                 currentDate = Moment(Date.now()).format("YYYY-MM-01");
                 futureMonth = Moment(currentDate).endOf('month').format(Setting.dateFormat);
            }else{
                 currentDate = Moment(month).format("YYYY-MM-01");
                 futureMonth = Moment(currentDate).endOf('month').format(Setting.dateFormat);
            }


            setLoading(true);
            axios.get(Setting.api + 'PublicCmsEvent/GetPublicByDate?fromDate='+currentDate+'&toDate='+futureMonth,
                {
                    method: "get",
                    headers: {
                        'Accept-Language': t.i18n.language,
                        'accept': 'text/plain',
                    }
                }
            ).then(async function (response) {
                setData(response.data);
                setLoading(false);
                setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);

            }).catch(function (error) {

            });
        }
        useEffect(() => {
            fetchDta("")
        }, [localStorage.getItem("lang") ]);




        const CheckDate = (d) => {
            const dates =  Moment(d).format("YYYY-MM-DD")
            const index =   data.findIndex( (array) => {
                return  Moment(array.timing).format("YYYY-MM-DD")  === dates
            })
            return index
        }
        const showResult = (d) => {
            const index = CheckDate(d)
            if(index != -1){
                setResult(data[index])
            }else{
                setResult(null)
            }
        }
        useEffect(()=>{
            showResult(Moment.date)
        },[])

        return(
            <Container className={"my-3"}>
            <div className={"bg-white shadow-lg"}>
                <Row className={"h-100"}>
                    <Col lg={3}   >
                        <div style={{backgroundColor:"#1b465c",color:"#fff"}} className={"h-100 p-3"}>
                            { result ?
                                <Link to={"/"+(Moment(Date.now()).isSameOrBefore(result.timing) ? "Event" : "EventHistory")+"/"+ result.id}  className={"h-100 d-block"}  style={{color:"#ffffff"}}  >
                                <div>
                                    <Row>
                                        <Col className={"text-end h5"} xs={6}>{days[Moment(result.timing).day()]}</Col>
                                        <Col className={"text-start "} xs={6}  ><span className={"fs-7"}>{Moment(result.timing).format("DD")} {month[Moment(result.timing).month()]} {Moment(result.timing).format("YYYY")}</span></Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className={"text-center mt-5"}>
                                            <span className={"bold eventday"}>{Moment(result.timing).format("DD")}</span>
                                        </Col>
                                        <Col md={12} className={"text-center"}>
                                            <span>{month[Moment(result.timing).month()]}</span>
                                        </Col>
                                        <Col md={12} className={"text-center mt-5"}>
                                            <span className={"h2"} dangerouslySetInnerHTML={{__html:result.name}}></span>
                                        </Col>
                                        <Col md={12} className={"text-center mt-5"}>
                                            <span className={"btn py-0 eventBtn"} style={btn}>
                                              {t.t("clock")} {Moment(result.timing).format("HH:mm")} {result.location && " - "+ result.location}
                                            </span>
                                        </Col>

                                    </Row>
                                </div>
                                </Link>
                                :
                                <div>

                                </div>
                            }

                        </div>

                    </Col>
                    <Col lg={9}>
                        <div className={"p-lg-4 p-0"}>
                            <Calendar  maxDetail={"month"} onActiveStartDateChange={ (action, activeStartDate, value, view ) => fetchDta(action.activeStartDate) }     onClickDay={(value)=>{showResult(value)}}  allowPartialRange={false}  tileContent={({ date, view }) => CheckDate(date) !==  -1  ? <p>{data[CheckDate(date)].name}</p> : null} onChange={onChange} value={value}  locale={t.i18n.language} />
                        </div>
                    </Col>
                </Row>
            </div>
            </Container>
        )
    }
    export const btn = {
        backgroundImage : 'url('+ Setting.sysUrl  + "images/bg/btn.png"+')',
        backgroundSize: " 100% 100%",
        fontsize:"14px !important"
    }
    export default Calenders
