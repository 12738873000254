import {Setting} from "../../helper/setting";
import {Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {setTitle} from "../../helper/function";

function AboutUs(){
    const t=useTranslation()
    return(
        <div className={"h-100"} style={{backgroundImage:'url('+Setting.sysUrl + "images/bg/bg2.jpg", backgroundSize:"cover",backgroundPosition:"bottom"}}>
            <Container className={"h-100"}>
                <Row  className={"align-items-center justify-content-center h-100"}>
                    <Col xl={10} lg={11} md={12}>
                        <Row className={"align-items-center  "}>
                            <Col md sm={12} xs={12}>
                                <Link to={"/staff/"+setTitle(t.t("staff"))} >
                                    <div className={"mbox my-2 py-4 mbox2"}>
                                        <div className={"mb-3"}><Image srcSet={Setting.sysUrl + "images/svg/about1.svg"} alt={""} /></div>
                                        <div className={"btn-link"}>{t.t("staff")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col md sm={12} xs={12}>
                                <Link to={"/departments/"+setTitle(t.t("departments"))} >
                                    <div className={"mbox my-2 py-4 mbox2"}>
                                        <div className={"mb-3"}><Image srcSet={Setting.sysUrl + "images/svg/about2.svg"} alt={""} /></div>
                                        <div className={"btn-link"}>{t.t("departments")}</div>
                                    </div>
                                </Link>
                            </Col>
                            <Col md sm={12} xs={12}>
                                <Link to={"/telephone/"+setTitle(t.t("phones"))} >
                                    <div className={"mbox my-2 py-4 mbox2"}>
                                        <div className={"mb-3"}><Image srcSet={Setting.sysUrl + "images/svg/about3.svg"} alt={""} /></div>
                                        <div className={"btn-link"}>{t.t("phones")}</div>
                                    </div>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}
export default AboutUs
