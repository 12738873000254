import {Col, Container, Image, Nav, Row} from "react-bootstrap";
import React from "react";
import {Setting} from "../../helper/setting";

function Template3(prop:any) {
    return(
            <Row >
                <Col>
                    <div className={"bg-white shadow-lg p-3 "}>
                        <Row>
                            {
                                (prop.image != '' && prop.image != null) &&

                                <Col md={3}>
                                    <div>
                                        <div className={"btn btn2 p-3  bg-gradient"}>
                                            <Image srcSet={Setting.imagePrefix + prop.image} width={"100%"}/>
                                        </div>
                                    </div>
                                </Col>
                            }
                            <Col md={(prop.image=='' || prop.image==null) ? 12 : 9}>
                                <div className={"color2"}>

                                    <div className={"h2  normal"} style={{lineHeight:1.4}}>{prop.title}</div>
                                    {prop.date != null &&
                                    <div className={"h6  normal text-start"}>{prop.date}</div>
                                    }


                                    <div className={"pt-3 text-justify"} dangerouslySetInnerHTML={{__html: prop.description}}></div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>

            </Row>

    )
}

export default Template3
