import {Col, Container, Image, Row, Spinner} from "react-bootstrap";
import {Setting} from "../../helper/setting";
import {Link} from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import ArticleRow from "../../component/news/articlerow";
import Loading from "../../component/loading/loading";

function News(prop:any){
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(Setting.rowInPage);
    const [search,setSearch] = useState('')

    const fetchDta = async (page:any,rows:any) => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsPage/GetByCategory?catId='+prop.id+'&page='+page+"&rows="+rows+search,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);

        }).catch(function (error) {

        });
    }
    useEffect(() => {
        fetchDta(1,perPage)
    }, [localStorage.getItem("lang") ]);


    return(
        <Container className={"my-3"}>
        <div className={"color2 p-4 bg-white"}>
            {loading ?
               <Loading /> :
                <>
                {
                    data.map((item)=>{
                    return(
                        <ArticleRow id={item['id']} image={Setting.imagePrefix+item['imageUrl']} title={item['title']} date={item['publishDate']} />
                    )
                    })
                }
                </>
            }

            {totalRows > data.length &&
                <Row>
                    <Col className={"text-center my-3"} md={12}>
                        <Image srcSet={Setting.sysUrl + "images/svg/bottom.svg"} width={"20px"} alt={"load more"}/>
                    </Col>
                </Row>
            }
        </div>
        </Container>
    )
}
export default News
