import {Breadcrumb, Col, Container, Nav, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import PageType from "./pagetype";
import Url from "../links";
import {getUrl} from "../../helper/function";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

function TabsPage(prop:any){
    const t= useTranslation()
    const [children, setchildren] = useState([]);
    const [info, setinfo]:any = useState(null);
    const [id, setId] = useState(0);
    const [parent, setParent]:any = useState([]);
    const [hash, sethash]:any = useState(window.location.hash);

    const openPage = (index:any) => {
        setId(prop.data.children[index].id)
        setinfo(prop.data.children[index])
        setParent(prop.data.parents)
        window.location.hash="id="+prop.data.children[index].id
    }

    useEffect(()=>{
        setchildren(prop.data.children)
        if(id == 0){
            if(hash == ""){
                openPage(0)
            }else{
                const index = prop.data.children.findIndex((item:any)=>{
                    return hash.replace("#id=","") == item.id
                })
                openPage(index)
            }
        }
    },[])

    return(
        <div className={"my-4  mb-5"}>
        <Container >

            {parent &&
            <>
                {parent.length > 0 &&
                <Row className={""}>
                    <Col>
                        <Breadcrumb dir={"rtl"}>
                            <Breadcrumb.Item><Url key={"board0"} href={"/"}>{t.t("homepage")}</Url></Breadcrumb.Item>
                            {
                                parent.slice(0).reverse().map((item: any) => {
                                    return (
                                        <Breadcrumb.Item><Url key={"board" + item.id}
                                                              href={getUrl(item)}>{item.name}</Url></Breadcrumb.Item>
                                    )
                                })
                            }
                        </Breadcrumb>
                    </Col>
                </Row>
                }
            </>

            }

            <Row>
                <Col sm={"12"}  >
                    <Nav className={"aboutus"}>
                        {children &&
                        <>
                            {
                                children.map((item:any,index) => {
                                    return (
                                        <Nav.Item key={"kk" + item.id} as={"li"}>
                                            <Nav.Link  eventKey={"tab" + item.id} defaultChecked={hash.replace("#id=","") == item.id ? true : false}
                                                      active={item.id == id ? true : false}
                                                      onClick={() => openPage(index)}>{item.name}</Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                        </>
                        }
                    </Nav>
                </Col>
            </Row>
        </Container>
        <div>
            {info &&
                <PageType data={info} breadcrumb={false} />
            }
        </div>

        </div>
    )
}

export default TabsPage
