import {Col, Container, Image, Row} from "react-bootstrap";
import {Setting} from "../../helper/setting";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Url from "../../component/links";




function CitizenService(){
    const t= useTranslation()

    const citzen_service = [
        {
            id:1,
            title : t.t("tmp.text31"),
            icon:"images/svg/a1.svg",
            link:'http://saasdemo.smsm-it.com/he/account/login'
        },{
            id:2,
            title : t.t("tmp.text32"),
            icon:"images/svg/a2.svg",
            link:'/Forms'
        },{
            id:3,
            title : t.t("tmp.text33"),
            icon:"images/svg/a3.svg",
            link:'/Informations'
        },{
            id:4,
            title : t.t("tmp.text34"),
            icon:"images/svg/a4.svg",
            link:'/Pay'
        },{
            id:5,
            title : t.t("tmp.text35"),
            icon:"images/svg/a5.svg",
            link:'/alltenders'
        },{
            id:6,
            title : t.t("tmp.text36"),
            icon:"images/svg/a6.svg",
            link:'#'
        },{
            id:7,
            title : t.t("tmp.text37"),
            icon:"images/svg/a7.svg",
            link:'https://reine.muni.il/ar/CmsPage/Page?pageId=167'
        },{
            id:8,
            title : t.t("tmp.text38"),
            icon:"images/svg/a8.svg",
            link:'https://www.hari-nazareth.org.il/he/'
        },{
            id:9,
            title : t.t("tmp.text39"),
            icon:"images/svg/a9.svg",
            link:'https://www.mavo.co.il/odot/Pages/kesher.aspx'
        },/*{
            id:10,
            title : t.t("tmp.text40"),
            icon:"images/svg/a10.svg",
            link:'https://sites.google.com/comunet.me/gis-rne/'
        }
        */
    ]

    return(
        <div className={"h-100"}>
        <Container className={"h-100"}>
            <Row  className={"align-items-center justify-content-center h-100"}>
                <Col md={12}>
                    <Row className={"align-items-center  "}>
                        {
                            citzen_service.map((item) => {
                                return (
                                    <Col lg={3} xs={6} sm={4} className={"my-3 col-20"}>
                                        <Url href={item.link} target={"_blank"}>
                                            <div className={"mbox mbox2"}>
                                                <div><Image srcSet={Setting.sysUrl + item.icon} style={{height:75}} /></div>
                                                <p className={"pb-0 pt-0 mb-1"}>{item.title}</p>
                                            </div>
                                        </Url>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
        </div>
    )
}
export default CitizenService
