import {Col, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Setting} from "../../helper/setting";
import {setTitle} from "../../helper/function";
import Moment from "moment";

function ArticleRow(prop:any){
    return(
        <Link to={"/article/"+prop.id+"/"+setTitle(prop.title)} className={"color2"} key={"ns"+prop.id} >
            <Row className={"align-items-center newsItem"} key={"news"+prop.id}>
                <Col md={2} >
                    <div className={"px-md-3 px-0 py-2"}>
                        <Image  srcSet={Setting.imagePrefix+prop.image} width={"100%"} />
                    </div>

                </Col>
                <Col md={8}><h2 className={"h6"} style={{lineHeight:1.5}}>{prop.title}</h2></Col>
                <Col md={2}>{Moment(prop.date).format(Setting.dateFormat + " - HH:mm")}</Col>
            </Row>
        </Link>
    )
}

export default ArticleRow
