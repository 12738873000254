import {Link} from "react-router-dom";

function Url({children,...prop}:any){
    const hrefData = prop.href == null ? "" :  prop.href as string
    return(
        <>
            {hrefData.includes("http://") || hrefData.includes("https://") ?
                <a href={prop.href} target={"_blank"} className={prop.className} >
                    {children}
                </a>

                :
                <Link to={hrefData} className={prop.className} >
                    {children}
                </Link>
            }
        </>
    )
}
export default Url
