import React, {useEffect, useState} from 'react';
import './App.css';
import { Route, Routes} from "react-router-dom";
import Home from "./views/main";
import {Row, Container, Col} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assets/css/style.css';

import Header from "./container/header";
import Footer from "./container/footer";
import Staff from "./views/aboutus/staff";
import EventTabs from "./views/news/main";
import EventDetail from "./views/events/eventdetails";
import EventDetailHistory from "./views/events/eventdetailshistory";
import CitizenService from "./views/citizen/main";
import Forms from "./views/forms/forms";
import Informations from "./views/citizen/information/informations";
import Information from "./views/citizen/information/Information";
import InfoPage from "./views/citizen/information/infopage";
import Protocols from "./views/protocol/protocols";
import Reports from "./views/report/reports";
import Pay from "./views/citizen/pay/main";
import Telephone from "./views/aboutus/telephone";
import AboutUs from "./views/aboutus/main";
import Emergency from "./views/emergency/main";
import PageDetails from "./views/pages/pagedetails";
import ContcatUs from "./views/contactus";
import Departments from "./views/department/departments";
import Department from "./views/department/departmentPage";
import {useTranslation} from "react-i18next";
import AllTenders from "./views/citizen/tender/main";
import Tenders from "./views/citizen/tender/tenders";
import ShowTender from "./views/citizen/tender/showtender";
import ArchiveTenders from "./views/citizen/tender/archivetenders";
import Article from "./views/news/details";
import Category from "./views/general/category";

function App() {
    const t = useTranslation()
    return (
        <div className="App h-100">

                <Container fluid={true} className={" px-0 mx-0   h-100"}>
                    <Row className={"h-100 px-0 mx-0 align-content-between"}>
                        <Col md={12} className={"px-0 mx-0"}>
                            <Header/>
                        </Col>
                        <Col md={12} className={"main px-0   mx-0"}>
                            <Routes>
                                <Route index element={<Home/>}/>
                                <Route path={"*"} element={<Home/>}/>
                                <Route path={"/AboutUs"} element={<AboutUs/>}/>
                                <Route path={"/departments/:title"} element={<Departments/>}/>
                                <Route path={"/staff/:title"} element={<Staff/>}/>
                                <Route path={"/telephone/:title"} element={<Telephone/>}/>
                                <Route path={"/Events"} element={<EventTabs/>}/>
                                <Route path={"/Events"} element={<EventTabs/>}/>
                                <Route path={"/Event/:id"} element={<EventDetail/>}/>
                                <Route path={"/EventHistory/:id"} element={<EventDetailHistory/>}/>
                                <Route path={"/department/:id/:title"} element={<Department/>}/>
                                <Route path={"/Citizen"} element={<CitizenService/>}/>
                                <Route path={"/Forms/:title"} element={<Forms/>}/>
                                <Route path={"/informations"} element={<Informations/>}/>
                                <Route path={"/Information"} element={<Information/>}/>
                                <Route path={"/InfoPage/:id"} element={<InfoPage/>}/>
                                <Route path={"/Protocols/:title"} element={<Protocols/>}/>
                                <Route path={"/cat/:catid/:title"} element={<Category/>}/>
                                <Route path={"/cat/:catid/:id/:title"} element={<Category/>}/>
                                <Route path={"/Reports"} element={<Reports/>}/>
                                <Route path={"/Pay"} element={<Pay/>}/>
                                <Route path={"/alltenders"} element={<AllTenders/>}/>
                                <Route path={"/tenders/:id/:title"} element={<Tenders/>}/>
                                <Route path={"/archivetenders/:id"} element={<ArchiveTenders/>}/>
                                <Route path={"/article/:id/:title"} element={<Article/>}/>
                                <Route path={"/showtender/:id"} element={<ShowTender/>}/>
                                <Route path={"/Emergency"} element={<Emergency/>}/>
                                <Route path={"/page/:id/:title"} element={<PageDetails/>}/>
                                <Route path={"/contactus/:title"} element={<ContcatUs/>}/>
                            </Routes>
                        </Col>
                        <Col md={12} className={"px-0 mx-0"}>
                            <Footer/>
                        </Col>
                    </Row>
                </Container>

     </div>

    );
}

export default App;
