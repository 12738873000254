import {Col, Container, Image, Row, Spinner} from "react-bootstrap";
import {Setting} from "../../helper/setting";
import axios from "axios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Card from "../../component/card";
import {setTitle} from "../../helper/function";
import Loading from "../../component/loading/loading";

function Departments(){
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchDta = async () => {
        setLoading(true);
        axios.get(Setting.api + 'PublicMuniDepartmentCms/Getall/Getall',
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);

        }).catch(function (error) {

        });
    };

    useEffect(() => {
        fetchDta();
    }, [localStorage.getItem("lang") ]);

    return(
        <Container className={"my-3"}>
            {loading ? <Loading /> :
            <Row>
                {
                    data.map((item) => {
                        return (
                            <Col lg={3} xs={6} sm={4} className={"mb-4 col-20"} key={"c" + item['id']} >
                                <Card  id={item['id']} title={item['name']} url={"../department/"+item['id']+"/"+setTitle(item['name'])} class={"mbox2"} image={Setting.imagePrefix+item['imageUrl']} />
                            </Col>
                        )
                    })
                }
            </Row>
            }
        </Container>
    )
}
export default Departments
