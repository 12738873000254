import {Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Setting} from "../../../helper/setting";
import {useTranslation} from "react-i18next";

function Pay(){
    const t= useTranslation()
    return(
        <div className={"h-100"}>
            <Container className={"h-100"}>
                <Row  className={"align-items-center justify-content-center h-100"}>
                    <Col md={12}>
                        <Row className={"align-items-center  "}>
                            <Col lg={3} xs={6} sm={6} className={"my-2"}>
                                <a href={ "https://city4u.co.il/PortalServicesSite/cityPay/905420/mislaka/1" } target={"_blank"} >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/p1.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text48")}</p>
                                    </div>
                                </a>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-2"}>
                                <a href={ "https://city4u.co.il/PortalServicesSite/cityPay/905420/mislaka/1" } target={"_blank"} >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/p2.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text49")}</p>
                                    </div>
                                </a>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-2"}>
                                <a href={ "https://www.cityedu.co.il/v2/CityEdu.aspx?CustomerID=905420&PageID=25001&group" } target={"_blank"} >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/p3.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text70")}</p>
                                    </div>
                                </a>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-2"}>
                                <Link to={ "#" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/p4.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text51")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-2"}>
                                <Link to={ "#" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/p5.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text52")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-2"}>
                                <Link to={ "#" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/p6.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text53")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-2"}>
                                <a href={ "https://city4u.co.il/PortalServicesSite/cityPay/905420/mislaka/1" } target={"_blank"} >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/p7.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text54")}</p>
                                    </div>
                                </a>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-2"}>
                                <Link to={ "#" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/p8.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text55")}</p>
                                    </div>
                                </Link>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Pay
