import {Col, Container, Image, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import React, {useState} from "react";
import {MenuList, Setting, webSetting} from "../helper/setting";
import {Link} from "react-router-dom";
import {getUrl, setTitle, UseLang} from "../helper/function";
import {useTranslation} from "react-i18next";
import Url from "../component/links";
function Header() {
    const t = useTranslation()
    const [expand,setExpand] = useState(false)
    return(
        <header >
            <Container>
                <Row>
                    <Col md={12}>
                        <Navbar bg="none" expand="lg" className={"py-0"} expanded={expand} >
                            <Container className={"gx-0"}>
                                <Navbar.Brand as={Link} to={"/"}  ><Image className={"logo"} srcSet={Setting.sysUrl + "images/logos/logo.png"} alt={"logo"} /></Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={()=>{setExpand(expand ==true ? false : true)}} />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="m-auto">
                                        {
                                            MenuList.header.map((item: any) => {
                                                return (
                                                    <Nav.Link key={"header"+item.id} as={Url} href={getUrl(item)} onClick={() => {setExpand(false)}}>{item.name}</Nav.Link>
                                                )
                                            })
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                                <Navbar.Collapse className="justify-content-end social">
                                    <Nav className="me-auto ">
                                        <Nav.Link> <UseLang position={"end"} /></Nav.Link>
                                        <Nav.Link href={"#"} title={"Search"} ><Image srcSet={Setting.sysUrl + "images/svg/search.svg"} alt={""}  /></Nav.Link>
                                        {
                                            webSetting.Facebook &&
                                            <Nav.Link href={webSetting.Facebook} title={"facebook"} target={"_blank"} ><Image srcSet={Setting.sysUrl + "images/svg/facebook.svg"} alt={""} /></Nav.Link>
                                        }
                                        {
                                            webSetting.Instagram &&
                                            <Nav.Link href={webSetting.Instagram} title={"instegram"} target={"_blank"} ><Image srcSet={Setting.sysUrl + "images/svg/instegram.svg"} alt={""}  /></Nav.Link>
                                        }
                                        {
                                            webSetting.Whatsapp &&
                                            <Nav.Link href={webSetting.Whatsapp} title={"whatsapp"} target={"_blank"}><Image
                                                srcSet={Setting.sysUrl + "images/svg/whatsapp.svg"}
                                                alt={""}/></Nav.Link>
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}
export default Header;
