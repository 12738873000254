import {Link, useParams} from "react-router-dom";
import {Col, Container, Image, Row, Spinner, Table} from "react-bootstrap";
import {Setting, webSetting} from "../../../helper/setting";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Moment from "moment";
import BreadCrumb from "../../../component/breadcrumb";

function ShowTender(){
    const t=useTranslation()
    let { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [category, setcategory] = useState([]);



    const fetchDta = async () => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsTender/Get?id='+id,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            document.title = webSetting.Title + " - " + response.data.title

            setLoading(false);
        }).catch(function (error) {

        });
    }

    const info = JSON.parse(JSON.stringify(data))

    useEffect(() => {
        fetchDta()
    }, [localStorage.getItem("lang") ]);

    return(
<>


         <Container className={"h-100"}>
            {loading ? <div className={"text-center"}><Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"}/></div> :
                <Row className={"color2  align-items-center justify-content-center h-100"}>
                    <Col lg={10} md={12}>

                        <BreadCrumb id={info.categoryId} main={8} breadcrumb={true} container={false} />

                        <div className={"text-center my-4"}>
                            <h1 className={" px-4 pb-2 my-3 d-inline-block  btn2 bg-gradient h3"}>{info.category}</h1>
                            <p className={"h5"}>{info.title}</p>
                            <p className={"bold h3"} dir={'ltr'}>{Moment(info.publicationTender).format("MM/YYYY")}</p>
                        </div>

                        <div className={"bg-white my-4 shadow-lg"}>
                            <Row className={"  "}>
                                <Col md={6}>
                                    <div className={"p-4 text-justify"} dangerouslySetInnerHTML={{__html:info.description}}>

                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className={"p-4"}>
                                        <Table style={{border: "1px solid #1b465c"}}>
                                            <tbody className={" color2"}>
                                            <tr>
                                                <td className={"bold"}>{t.t("publicationTender")}</td>
                                                <td>{Moment(info.publicationTender).format(Setting.dateFormat)}</td>
                                            </tr>
                                            <tr>
                                                <td className={"bold"}>{t.t("deadlineSubmission")}</td>
                                                <td>{info.deadlineSubmission ? Moment(info.deadlineSubmission).format(Setting.dateFormat) : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td className={"bold"}>{t.t("biddersConferenceTime")}</td>
                                                <td>{info.biddersConferenceTime ? Moment(info.biddersConferenceTime).format(Setting.dateFormat) : "-"}</td>
                                            </tr>
                                            <tr>
                                                <td className={"bold"}>{t.t("deadlineForClarificationQuestions")}</td>
                                                <td>{info.deadlineForClarificationQuestions ? Moment(info.deadlineForClarificationQuestions).format(Setting.dateFormat +   " " + t.t('hour') + " " + "hh:mm") : "-"}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                        {info.files &&
                                            <div>
                                            <hr/>
                                            <Table style={{border: "1px solid #1b465c"}}>
                                            <tbody>
                                            {
                                                info.files.map((item:any)=>{
                                                    return(
                                                        <tr key={"item"+item.id}>
                                                            <td className={"py-1"}>{item.title}</td>
                                                            <td className={"py-1"}>
                                                                <a href={item.fileUrl} className={"color4"} target={"_blank"}>
                                                                    <Image srcSet={Setting.sysUrl + "images/svg/pdf.svg"} height={25}/> {t.t("download")}
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                            </Table>
                                            </div>
                                        }
                                        <Row className={"justify-content-center"}>
                                            {info.onlineSumission &&
                                            <Col md={6}>
                                                <a href={info.onlineSumission} target={"_blank"}
                                                   className={"btn w-100 py-1 shadow-md r-0 bg-gradient bg-yallow"}>{t.t("onlineSubmission")}</a>
                                            </Col>
                                            }
                                            {info.purchaseOfTender &&

                                            <Col md={6}>
                                                <a href={info.purchaseOfTender} target={"_blank"}
                                                   className={"btn w-100 shadow-md btn2 py-1 bg-gradient"}>{t.t("purchaseOfTender")}</a>
                                            </Col>
                                            }
                                        </Row>
                                    </div>


                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            }
        </Container>
</>
    )
}
export default ShowTender

