import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Setting} from "../helper/setting";
import {useState} from "react";

function ContcatUs(){
    const t=useTranslation()
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (event: any) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (form.checkValidity() === true) {
            event.preventDefault();
            setLoading(true)
            axios.post(Setting.api + 'CmsContactus/Post',
                {
                    "firstName": event.target.firstName.value,
                    "lastName": event.target.lastName.value,
                    "phone": event.target.phone.value,
                    "address": event.target.address.value,
                    "section": event.target.section.value,
                    "email": event.target.email.value,
                    "subject": event.target.subject.value,
                    "description": event.target.description.value
                },
                {
                    method: "post",
                    headers: {
                        'Accept-Language':localStorage.getItem("lang") as string,
                        'content-type': 'application/json',
                    }
                }
            ).then(async function (response) {
                setLoading(false)
                setValidated(false)
            }).catch(function (error) {
                setLoading(false)
            });
        }
    };


    return(
            <Container className={"h-100"}>
                <Row  className={"align-items-center justify-content-center h-100"}>
                    <Col md={12}>
                        <Row className={"align-items-center  justify-content-center"}>
                            <Col lg={8}  className={"my-5"}>
                                <div className={"h3 color2 mb-3"}>{t.t("contactUs")}</div>
                                <div className={"bg-white shadow-lg p-4"}>

                                    <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                                        <Row className={"align-items-center  justify-content-center"}>
                                            {loading ?
                                                <Alert className={"text-center my-5"} variant={"success"} >{t.t("emailSend")}</Alert>
                                                :
                                                <>
                                                    <Col lg={6} className={"my-2"}>
                                                        <Form.Group controlId={"firstName"}>
                                                            <Form.Control required type={"text"}
                                                                          aria-describedby="msg_firstName"
                                                                          aria-label={t.t("firstName")}
                                                                          placeholder={"* " + t.t("firstName")}/>
                                                            <Form.Control.Feedback type="invalid" id="msg_firstName">
                                                                {t.t("required")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className={"my-2"}>
                                                        <Form.Group controlId={"lastName"}>
                                                            <Form.Control required type={"text"}
                                                                          aria-describedby="msg_lastName"
                                                                          aria-label={t.t("lastName")}
                                                                          placeholder={"* " + t.t("lastName")}/>
                                                            <Form.Control.Feedback type="invalid" id="msg_lastName">
                                                                {t.t("required")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className={"my-2"}>
                                                        <Form.Group controlId={"phone"}>
                                                            <Form.Control required type={"tel"}
                                                                          aria-describedby="msg_phoneNum"
                                                                          aria-label={t.t("phoneNum")}
                                                                          placeholder={"* " + t.t("phoneNum")}/>
                                                            <Form.Control.Feedback type="invalid" id="msg_phoneNum">
                                                                {t.t("required")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className={"my-2"}>
                                                        <Form.Group controlId={"address"}>
                                                            <Form.Control type={"text"} aria-describedby="msg_address"
                                                                          aria-label={t.t("address")}
                                                                          placeholder={t.t("address")}/>
                                                            <Form.Control.Feedback type="invalid" id="msg_address">
                                                                {t.t("required")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className={"my-2"}>
                                                        <Form.Group controlId={"section"}>
                                                            <Form.Control required type={"text"}
                                                                          aria-describedby="msg_department"
                                                                          aria-label={t.t("department")}
                                                                          placeholder={"* " + t.t("department")}/>
                                                            <Form.Control.Feedback type="invalid" id="msg_department">
                                                                {t.t("required")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={6} className={"my-2"}>
                                                        <Form.Group controlId={"email"}>
                                                            <Form.Control required type={"email"}
                                                                          aria-describedby="msg_email2"
                                                                          aria-label={t.t("email2")}
                                                                          placeholder={"* " + t.t("email2")}/>
                                                            <Form.Control.Feedback type="invalid" id="msg_email2">
                                                                {t.t("required")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={12} className={"my-2"}>
                                                        <Form.Group controlId={"subject"}>
                                                            <Form.Control required type={"text"}
                                                                          aria-describedby="msg_subject"
                                                                          aria-label={t.t("subject")}
                                                                          placeholder={"*  " + t.t("subject")}/>
                                                            <Form.Control.Feedback type="invalid" id="msg_subject">
                                                                {t.t("required")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={12} className={"my-2"}>
                                                        <Form.Group controlId={"description"}>
                                                            <Form.Control required rows={3}
                                                                          aria-describedby="msg_content" as={"textarea"}
                                                                          aria-label={t.t("content")}
                                                                          placeholder={"*  " + t.t("content")}/>
                                                            <Form.Control.Feedback type="invalid" id="msg_content">
                                                                {t.t("required")}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className={"mt-4"}>

                                                        <Button type={"submit"}
                                                                className={"btn w-100 btn2 border-0 h1"}>{t.t("send")}</Button>
                                                    </Col>
                                                </>
                                            }
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
    )
}
export default ContcatUs
