import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Setting} from "../helper/setting";

function Card(prop:any){
    return(
        <Link key={"L"+prop.id} to={prop.url} >
            <div className={"mbox " + prop.class}>
                <div><Image srcSet={Setting.imagePrefix + prop.image}/></div>
                <p className={"pb-0 pt-3"}>{prop.title}</p>
            </div>
        </Link>
    )
}
export default Card
