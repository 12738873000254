import {Link, useParams} from "react-router-dom";
import React, { useState} from "react";
import Page from "./page";
import BreadCrumb from "../../component/breadcrumb";

function PageDetails(){
    let { id } = useParams();
    const [data,setData] = useState(null)

     return(

         <>

             <Page id={id} />
         </>


        )
}
export default PageDetails
