import React from "react"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

function PRow({ children }) {

    return(
        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3,1200:4}}>
            <Masonry columnsCount={4} gutter={15}>
                {children}
            </Masonry>
        </ResponsiveMasonry>
    )
}
export default PRow
