import React, {useEffect, useState} from 'react';
import {BrowserRouter, useLocation, useNavigate, useParams} from "react-router-dom";
import i18n from "i18next";
import {Lang, MenuList, Setting, webSetting} from "./helper/setting";
import App from "./App";
import {checkLang} from "./helper/function";
import SEO from "./component/seo";
import axios from "axios";
import LoaderContainer from "./component/loading/script";
import './i18n';

function MainPage(){

    const [lang,setLang] = useState('')
    const [config,setConfig] = useState(true)
    const [meta,setmeta] = useState({})

    useEffect(() => {
        const parsedData = window.location.pathname.split("/")
        if(parsedData[1].replace("/","") == ""){
            setLang(Lang[0].label)
            localStorage.setItem("dir",Lang[0].dir)
            localStorage.setItem("lang",Lang[0].label)
            localStorage.setItem("title",Lang[0].title)
            localStorage.setItem("langId","0")
            i18n.changeLanguage(Lang[0].label)
            window.location.href = "/"+Lang[0].label
        }else{
            const index = checkLang(parsedData[1].replace("/",""))
            if(index == -1){
                setLang(Lang[0].label)
                localStorage.setItem("dir",Lang[0].dir)
                localStorage.setItem("lang",Lang[0].label)
                localStorage.setItem("title",Lang[0].title)
                localStorage.setItem("langId","0")
                i18n.changeLanguage(Lang[0].label)
            }else{
                setLang(Lang[index].label)
                localStorage.setItem("dir",Lang[index].dir)
                localStorage.setItem("lang",Lang[index].label)
                localStorage.setItem("title",Lang[index].title)
                localStorage.setItem("langId",String(index))
                i18n.changeLanguage(Lang[index].label)
            }
        }

    }, [localStorage.getItem("langId"),window.location.pathname]);
    const GetPublicSetting = async () => {
        setConfig(true)
        axios.get(Setting.api + 'PublicCmsSystemSetting/GetPublicSettings',
            {
                method: "get",
                headers: {
                    'Accept-Language': localStorage.getItem("lang") as string,
                    'accept': 'text/plain',
                }
            }
        ).then(async function (response) {
            webSetting.Title = response.data[checkCode("pageTitle", response.data)]['value']
            webSetting.Description = response.data[checkCode("pageDescription", response.data)]['value']
            webSetting.Keyword = response.data[checkCode("pageKeyword", response.data)]['value']
            webSetting.Email = response.data[checkCode("email", response.data)]['value']
            webSetting.Facebook = response.data[checkCode("facebook", response.data)]['value']
            webSetting.Instagram = response.data[checkCode("instagram", response.data)]['value']
            webSetting.Whatsapp = response.data[checkCode("whatsapp", response.data)]['value']

            setmeta({
                title: webSetting.Title,
                description: webSetting.Description,
                canonical: window.location.href,
                meta: {
                    charset: 'utf-8',
                    name: {
                        keywords: webSetting.Keyword
                    },
                    property: {
                        "og:title": webSetting.Title,
                        "og:description": webSetting.Description,
                        "og:site_name": webSetting.Title,
                        "og:url": window.location.href
                    }
                }
            })

            setTimeout(() => {
                setConfig(false)
            }, 1000)
        }).catch(function (error) {
        });
    }



    const GetGeneralCategory = () => {
        setConfig(true)
        axios.get(Setting.api + 'PublicCmsGeneralCategory/HomeCmsGeneralCategory',
                {
                    method: "get",
                    headers: {
                        'Accept-Language': localStorage.getItem("lang") as string,
                        'accept': 'text/plain',
                    }
                }
            ).then(async function (response) {

                MenuList.header = response.data.categories.headerList
                MenuList.boxes = response.data.categories.midLocationList
                MenuList.robbin = response.data.categories.yallowList

                webSetting.Title = response.data.systemSetting[checkCode("pageTitle", response.data.systemSetting)]['value']
                webSetting.Description = response.data.systemSetting[checkCode("pageDescription", response.data.systemSetting)]['value']
                webSetting.Keyword = response.data.systemSetting[checkCode("pageKeyword", response.data.systemSetting)]['value']
                webSetting.Email = response.data.systemSetting[checkCode("email", response.data.systemSetting)]['value']
                webSetting.Facebook = response.data.systemSetting[checkCode("facebook", response.data.systemSetting)]['value']
                webSetting.Instagram = response.data.systemSetting[checkCode("instagram", response.data.systemSetting)]['value']
                webSetting.Whatsapp = response.data.systemSetting[checkCode("whatsapp", response.data.systemSetting)]['value']

                setmeta({
                    title: webSetting.Title,
                    description: webSetting.Description,
                    canonical: window.location.href,
                    meta: {
                        charset: 'utf-8',
                        name: {
                            keywords: webSetting.Keyword
                        },
                        property: {
                            "og:title": webSetting.Title,
                            "og:description": webSetting.Description,
                            "og:site_name": webSetting.Title,
                            "og:url": window.location.href
                        }
                    }
                })
                setTimeout(() => {
                    setConfig(false)
                }, 1000)
            }).catch(function (error) {
            });
    }

    const checkCode = (code:any,data:any)=>{
        const index =  data.findIndex((value:any) =>{
            return value["code"] === code
        })
        return index;
    }
    useEffect(() => {
        GetGeneralCategory()
        //GetPublicSetting()
    }, [localStorage.getItem("lang") ]);


    return(
        <>{
            config ?
                <LoaderContainer/>
                :
                <SEO prop={meta}>
                    <BrowserRouter basename={lang}>
                        <App/>
                    </BrowserRouter>
                </SEO>
        }
        </>
    )
}
export default MainPage
