import {Link, useParams} from "react-router-dom";
import {Col, Container, Image, Row} from "react-bootstrap";
import {Setting, webSetting} from "../../helper/setting";
import {useTranslation} from "react-i18next";
import Moment from "moment";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Loading from "../../component/loading/loading";
import BreadCrumb from "../../component/breadcrumb";
import {useTitle} from "../../helper/function";

function EventDetail(){
    let { id } = useParams();
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const days = [t.t("sun"),t.t("mon"),t.t("tue"),t.t("wed"),t.t("thu"),t.t("fri"),t.t("sat")]
    const month = [t.t('jan'),t.t('feb'),t.t('mar'),t.t('apr'),t.t('may'),t.t('jun'),t.t('jul'),t.t('aug'),t.t('sep'),t.t('oct'),t.t('nov'),t.t('dec')]

    const fetchDta = async () => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsEvent/Get?id='+id,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
            document.title = webSetting.Title + " - "+response.data.name
        }).catch(function (error) {

        });
    }


    useEffect(() => {
        fetchDta()
    }, [localStorage.getItem("lang") ]);

    const info = JSON.parse(JSON.stringify(data))
    return(
        <Container className={"h-100"}>
            <Row className={"  align-items-center justify-content-center h-100"}>
                {loading ? <Loading /> :
                    <Col lg={8} md={10}>

                        <BreadCrumb id={2} main={4} breadcrumb={true} container={false} />


                        <div className={"bg-white my-4 shadow-lg"}>
                            <Row className={"  "}>
                                <Col md={4}>
                                    <div style={{backgroundColor:"#1b465c",color:"#fff"}} className={"h-100 text-center"}>
                                        <Image srcSet={Setting.imagePrefix+info.imageUrl} alt={""} width={"100%"} />
                                        <h3 className={" my-4"}>{info.name}</h3>
                                        <a href={"#"} style={btn} className={"px-2 mb-3 d-inline-block"}  >
                                            {days[Moment(info.timing).day()]}, {Moment(info.timing).format("DD")} {month[Moment(info.timing).month()]} {Moment(info.timing).format("YYYY")} {t.t("clock")} {Moment(info.timing).format("HH:mm")}
                                        </a>
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div className={"p-3 color2 py-md-5 py-4"}>
                                        <p dangerouslySetInnerHTML={{__html:info.description}}>
                                        </p>


                                        {info.externalLink &&
                                        <div className={"text-center mt-4  "}>
                                            <a href={info.externalLink} target={"_blank"} className={"btn btn2 py-0 px-5 bg-gradient"}>{t.t("reg")}</a>
                                        </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                }
            </Row>
        </Container>
    )
}
export default EventDetail
export const btn = {
    backgroundImage : 'url('+ Setting.sysUrl  + "images/bg/btn.png"+')',
    backgroundSize: " 100% 100%",
    fontSize:13,
    color:"#fff"
}
