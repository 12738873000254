import {Col, Container, Form, Image, Row, Spinner, Table} from "react-bootstrap";
import {Setting, webSetting} from "../../../helper/setting";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Moment from "moment";
import DataTable from "react-data-table-component";
import BreadCrumb from "../../../component/breadcrumb";

function Tenders(){
    const t= useTranslation()
    let { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [category, setcategory] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(Setting.rowInPage);
    const [categoryName, setcategoryName] = useState('');


    const columns = [
        {
            sortable: true,
            name: t.t("tmp.text62"),
            cell: (row : any) => <Link className={"color4"} to={"/showtender/"+row.id}>{row.title}</Link>,
        },{
            sortable: false,
            center:true,
            name: t.t("tmp.text63"),
            selector: (row : any) => Moment(row.publicationTender).format(Setting.dateFormat),
        },{
            sortable: false,
            name: t.t("tmp.text64"),
            center:true,
            cell: (row : any) => <>{row.deadlineSubmission != null ? Moment(row.deadlineSubmission).format(Setting.dateFormat) :  "-"}</>,
        }

    ]
    const fetchDta = async (page:any,rows:any) => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsTender/GetActiveTender/getactivetender?type='+id+'&page='+page+"&rows="+rows,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.listTerm);
            setLoading(false);
            document.title = webSetting.Title + " - " + response.data.categoryName

            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);
            setcategoryName(response.data.categoryName)
        }).catch(function (error) {

        });
    }
    const handlePageChange = (page :any) => {
        fetchDta(page,perPage);
    }
    const handlePerRowsChange = async (newPerPage:any, page:any) => {

        setPerPage(newPerPage);
        fetchDta(page,newPerPage)
    }


    useEffect(() => {
        fetchDta(1,perPage)
    }, [localStorage.getItem("lang") ]);

    return(
        <Container className={"my-5"}>
            <Row>
                <Col>

                    <BreadCrumb id={id} main={8} breadcrumb={true} container={false} />


                    {!loading &&
                    <div className={"text-center"}><h1
                        className={" px-4 pb-2 my-3 d-inline-block  btn2 bg-gradient h1"}>{categoryName}</h1></div>
                    }
                    <div className={"mb-4  p-3 bg-white"}>


                        <DataTable
                            noDataComponent={t.t("noData")}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationPerPage={perPage}
                            progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange} columns={columns} data={data} />




                    </div>
                    <div className={"text-center color2 mt-3"}>
                        <Link className={"color4"} to={"/archivetenders/"+id}>
                        <Image srcSet={Setting.sysUrl + "images/svg/folder.svg"}   height={60}  />
                        <p className={"bold h5"}>{t.t("tmp.text65")}</p>
                        </Link>
                    </div>

                </Col>
            </Row>
        </Container>
    )
}
export default Tenders
