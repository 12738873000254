import {Col, Container, Image, Nav, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Report from "./report";
import axios from "axios";
import {Setting, webSetting} from "../../helper/setting";
import {useTranslation} from "react-i18next";

function Reports(){
    const t = useTranslation()
    const [category, setcategory] = useState([]);
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    const openPage = (id:any) => {
        setId(id)
    }

    const getCategory = async () => {
        setLoading(true)
        axios.get(Setting.api + 'PublicCmsCategory/GetByCategory?type=4',
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setcategory(response.data);
            //document.title = webSetting.Title + " - " + response.data.name

            setId(response.data[0].id)
            setLoading(false)

        }).catch(function (error) {
        });
    };

    useEffect(() => {
        getCategory()
    }, [localStorage.getItem("lang")]);


    return(
        <Container className={"my-5  mb-5"}>
            {loading ? <div className={"text-center"}><Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"}/></div> :
                <Row>
                    <Col sm={"12"} className={"mb-4"}>
                        <Nav className={"aboutus"}>
                            {category &&
                            <>
                                {
                                    category.map((item) => {
                                        return (
                                            <Nav.Item key={"kk" + item['id']} as={"li"}>
                                                <Nav.Link eventKey={"tab" + item['id']}
                                                          active={item['id'] == id ? true : false}
                                                          onClick={() => openPage(item['id'])}>{item['name']}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                }
                            </>
                            }
                        </Nav>
                    </Col>
                    <Col sm={12}>

                        <div>
                            <Report id={id}/>
                        </div>

                    </Col>
                </Row>
            }
        </Container>
    )
}
export default Reports
