import {Col, Image} from "react-bootstrap";
import {getUrl} from "../../helper/function";
import Url from "../links";
import Cols from "../grid/col";
import {Setting} from "../../helper/setting";

function Box1(prop:any){
    return(
        <Cols total={prop.total}>
            <Url href={getUrl(prop.data)} >
                 <div className={"mbox " + (prop.total > 3 ? " mbox2" : " ")}>
                        <div><Image srcSet={Setting.imagePrefix + prop.data.imageUrl} style={{height:(prop.total > 3 ? 75 : 120)}} /></div>
                        <p className={"pb-0 pt-0 mb-1"}>{prop.data.name}</p>
                    </div>
            </Url>
        </Cols>
    )
}

export default Box1
