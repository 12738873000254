import {Accordion, Button, Col, Container, Form, Image, Row, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Setting, webSetting} from "../../helper/setting";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Moment from "moment";
import DataTable from "react-data-table-component";

function Report(prop:any){
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(Setting.rowInPage);

    const [search,setSearch] = useState('')

    const Search = (event:any) => {
        event.preventDefault()

        let s = ''
        if(event.target.searchbyname.value != ""){
            s += '&search=' + event.target.searchbyname.value;
        }
        if(event.target.searchbydate.value != ""){
            s += '&date=' + event.target.searchbydate.value;
        }

        setSearch(s)

    }
    const columns = [
        {
            sortable: true,
            name: t.t("tmp.text26"),
            selector: (row : any) => row.title,
        },{
            sortable: false,
            center:true,
            name: t.t("tmp.text27"),
            selector: (row : any) => Moment(row.date).format(Setting.dateFormat),
        },{
            sortable: false,
            name: t.t("tmp.text28"),
            center:true,
            cell: (row : any) => <a href={row.files.length > 0 ? Setting.imagePrefix + row.files[0].fileUrl : "#"} target={"_blank"} ><Image srcSet={Setting.sysUrl + "images/svg/folder.svg"} style={{height:35}} /></a>,
        }

    ]


    const fetchDta = async (page:any,rows:any) => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsReportProtocol/GetReportsByCategory?catId='+prop.id+'&page='+page+"&rows="+rows+search,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);

        }).catch(function (error) {

        });
    }
    const handlePageChange = (page :any) => {
        fetchDta(page,perPage);
    }
    const handlePerRowsChange = async (newPerPage:any, page:any) => {
        console.log(newPerPage)
        console.log(page)

        setPerPage(newPerPage);
        fetchDta(page,newPerPage)
    }



    useEffect(() => {
         fetchDta(1,perPage)

    }, [localStorage.getItem("lang") ,prop.id,search]);

    return(
        <Container className={"my-3"}>
            <Row >
                <Col md={12}>
                    <div>
                        <Accordion   defaultActiveKey="0"   >
                            <Accordion.Item eventKey="1"    className={"border-0"} style={{backgroundColor:"transparent"}}>
                                <Accordion.Header   className={"d-inline-block py-0"}>{t.t("tmp.text22")}</Accordion.Header>
                                <Accordion.Body className={"mb-2"} style={{backgroundColor:"#fff"}}>
                                    <Form onSubmit={(e)=>{Search(e)}}>
                                        <Row>
                                            <Col md={11}>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Control id={"searchbyname"} style={{border:0,borderBottom:"1px solid"}} aria-label={t.t("tmp.text26")} placeholder={t.t("tmp.text26")} />
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Control id={"searchbydate"} type={"date"} style={{border:0,borderBottom:"1px solid"}} aria-label={t.t("tmp.text25")} placeholder={t.t("tmp.text25")} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={1}>
                                                <Button  type={"submit"} className={"py-0"} aria-label={t.t("tmp.text22")} variant={"none"}><Image srcSet={Setting.sysUrl + "images/svg/search2.svg"} style={{height:40}} /></Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className={"text-center"} >

                            <DataTable
                                noDataComponent={t.t("noData")}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                paginationPerPage={perPage}
                                progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange} columns={columns} data={data} />

                        </div>

                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Report
