import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import Box from "../boxes/boxes";
import {useEffect, useState} from "react";
import News from "../../views/news/news";
import Calenders from "../../views/events/calender";
import Events from "../../views/events/events";
import EventHistory from "../../views/events/eventhistory";
import Report from "../../views/report/report";
import Departments from "../../views/department/departments";
import Department from "../../views/department/departmentPage";
import Forms from "../../views/forms/forms";
import Page from "../../views/pages/page";
import Protocols from "../../views/protocol/protocols";
import Tenders from "../../views/citizen/tender/tenders";
import ShowTender from "../../views/citizen/tender/showtender";
import AllTenders from "../../views/citizen/tender/main";
import ContcatUs from "../../views/contactus";
import Telephone from "../../views/aboutus/telephone";
import Url from "../links";
import {getUrl} from "../../helper/function";
import {useTranslation} from "react-i18next";

function PageType(prop:any){
    const t= useTranslation()
    const info = prop.data
    const [parent, setParent]:any = useState([]);
    useEffect(()=>{
        setParent(prop.data.parents)
    },[prop.data.id])

    return(

        <>
            {parent &&
            <Container>
                {parent.length > 0 &&
                <Row className={"pt-4"}>
                    <Col>
                        <Breadcrumb dir={"rtl"}>
                            <Breadcrumb.Item><Url key={"board0"} href={"/"}>{t.t("homepage")}</Url></Breadcrumb.Item>
                            {
                                parent.slice(0).reverse().map((item: any) => {
                                    return (
                                        <Breadcrumb.Item key={item.id}><Url key={"board" + item.id}
                                                              href={getUrl(item)}>{item.name}</Url></Breadcrumb.Item>
                                    )
                                })
                            }
                        </Breadcrumb>
                    </Col>
                </Row>
                }
            </Container>
            }

            <div>
                    {info.mainCategoryId == 1 &&
                        <>
                        {info.subCategoryId > 0 ? <Department  /> : <Departments  /> }
                        </>
                    }
                    {info.mainCategoryId == 2 &&
                        <News id={info.subCategoryId} />
                    }
                    {info.mainCategoryId == 3 &&
                        <Forms  />
                    }
                    {info.mainCategoryId == 4 &&
                        <>
                            {info.subCategoryId == 1 &&
                                <Calenders />
                            }
                            {info.subCategoryId == 2 &&
                                <Events />
                            }
                            {info.subCategoryId == 3 &&
                                <EventHistory />
                            }
                        </>
                    }
                    {info.mainCategoryId == 5 &&
                        <Page id={info.subCategoryId} breadcrumb={prop.breadcrumb}  />
                    }
                    {info.mainCategoryId == 6 &&
                        <Protocols  />
                    }
                    {info.mainCategoryId == 7 &&
                        <Report id={info.subCategoryId} />
                    }
                    {info.mainCategoryId == 8 &&
                        <>{info.subCategoryId > 0 ? <AllTenders  /> : <ShowTender  /> }</>
                    }
                    {info.mainCategoryId == 9 &&
                        <ContcatUs />
                    }
                    {info.mainCategoryId == 10 &&
                        <Telephone />
                    }

            </div>
        </>
    )
}

export default PageType
