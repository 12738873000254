import {Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Setting} from "../../helper/setting";
import {useTranslation} from "react-i18next";


function Emergency(){
    const t= useTranslation()
    return(
        <div className={"h-100"}>
            <Container className={"h-100 py-4"}>
                <Row  className={"align-items-center justify-content-center h-100"}>
                    <Col md={12}>
                        <Row className={"align-items-center  "}>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Link to={ "/Page/27" } >
                                    <div className={"mbox "}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/e1.svg"}  style={{height:120}}  /></div>
                                        <p className={"py-1 h4 "}>{t.t('tmp.text71')}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Link to={ "/Page/28" } >
                                    <div className={"mbox "}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/e2.svg"} style={{height:120}}  /></div>
                                        <p className={"py-1  h4  "}>{t.t('tmp.text72')}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Link to={ "/Page/29" } >
                                    <div className={"mbox "}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/e3.svg"}  style={{height:120}} /></div>
                                        <p className={"py-1 h4   "}>{t.t('tmp.text73')}</p>
                                    </div>
                                </Link>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Emergency
