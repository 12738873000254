import {Col, Image} from "react-bootstrap";
import {getUrl} from "../../helper/function";
import Url from "../links";
import Cols from "../grid/col";
import {Setting} from "../../helper/setting";

function Box3(prop:any){
    return(
        <Cols total={prop.total}>
            <Url href={getUrl(prop.data)} >
                <div className={"mbox my-2 py-4 mbox2"}>
                    <div className={"mb-3"}><Image srcSet={Setting.imagePrefix + prop.data.imageUrl} alt={""} /></div>
                    <div className={"btn-link"}>{prop.data.name}</div>
                </div>
            </Url>
        </Cols>
    )
}

export default Box3
