import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Setting, webSetting} from "../../helper/setting";
import {useParams} from "react-router-dom";
import {Container, Spinner} from "react-bootstrap";
import Template1 from "../../component/pages/template1";
import Template2 from "../../component/pages/template2";
import Template3 from "../../component/pages/template3";
import Moment from "moment";
import Loading from "../../component/loading/loading";
import BreadCrumb from "../../component/breadcrumb";

function Article(){
    const {id} = useParams()
    const t=useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsPage/Get?id='+id,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            document.title = webSetting.Title + " - " + response.data.title
            setLoading(false);
        }).catch(function (error) {

        });
    };

    useEffect(() => {
        fetchDta(1);
    }, [localStorage.getItem("lang") ]);

    const info = JSON.parse(JSON.stringify(data))

    return(
        <>
            <Container className={"my-4 py-4"}>


                {loading ?
                    <Loading /> :
                    <div className={"customPage "}>

                        <BreadCrumb id={id} main={2} breadcrumb={true} container={false} />


                        <Template3 image={Setting.imagePrefix+info.imageUrl} title={info.title} description={info.description} date={Moment(info.createdOn).format(Setting.dateFormat)} />

                    </div>
                }
            </Container>

        </>

    )
}
export default Article
