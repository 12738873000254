import {Image} from "react-bootstrap";
import {Setting} from "../../helper/setting";
import {Link} from "react-router-dom";

function EventHistoryCol(prop:any) {

    return(
        <Link to={"/EventHistory/"+prop.id}>
            <div className={"bg-white h-100 mb-3"}>
                <Image   srcSet={Setting.imagePrefix + prop.image}  width={"100%"} alt={""} />
                <p className={"h6 p-3 color2 text-center"} style={{lineHeight:1.5}}>{prop.title}</p>
            </div>
        </Link>
    )
}
export default EventHistoryCol
