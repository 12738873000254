import Box1 from "./box1";
import Box2 from "./box2";
import Box3 from "./box3";

function Box(prop:any){
    return(
        <>
            {prop.template == 2 &&
                <Box1 total={prop.total} data={prop.data}/>
            }
            {prop.template == 3 &&
                <Box2 total={prop.total} data={prop.data}/>
            }
            {prop.template == 4 &&
                <Box3 total={prop.total} data={prop.data}/>
            }
        </>
    )
}
export default Box
