import {Accordion, Button, Col, Container, Form, Image, Row, Spinner, Table} from "react-bootstrap";
import {Setting} from "../../helper/setting";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import React, {useEffect, useState} from "react";
import DataTable from "react-data-table-component";

function Forms(){
    const t= useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [category, setcategory] = useState([]);

    const [search,setSearch] = useState('')

    const Search = (event:any) => {
        event.preventDefault()

        let s = ''
        if(event.target.searchbyname.value != ""){
            s += '&search=' + event.target.searchbyname.value;
        }


        if(event.target.searchbycat.value != ""){
            s += '&catId=' + event.target.searchbycat.value;
        }

        setSearch(s)
    }

    const getCategory = async () => {
        axios.get(Setting.api + 'PublicCmsCategory/GetByCategory?type=2',
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setcategory(response.data);
        }).catch(function (error) {
        });
    };



    const columns = [
        {
            sortable: true,
            name: t.t("formName"),
            selector: (row : any) => row.title,
        },{
            sortable: true,
            name: t.t("category"),
            center:true,
            selector: (row : any) => row.categoryName,
        },{
            sortable: false,
            center:true,
            /*hide:786,*/
            name: t.t("onlineoffline"),
            cell: (row : any) => <div className={"justify-content-center"} >{(row.outlink != null && row.outlink != '') ? t.t("online")  :  t.t("offline") }</div> ,
        },{
            minWidth:"200px",
            sortable: false,
            name: "",
            center:true,
            cell: (row : any) => <div className={"justify-content-center"} >{((row.outlink != null && row.outlink != '') ? <a href={row.outlink} target={"_blank"}   className={"btn btn2 py-0 px-5 bg-gradient"}>{t.t("tmp.text47")}</a>  : (row.file != null ?  <a href={ Setting.imagePrefix + row.file} target={"_blank"}><Image srcSet={Setting.sysUrl + "images/svg/pdf.svg"} style={{height: 35}}/></a> : "") )}</div> ,
        }

    ]

    const [totalRows, setTotalRows] = useState(0);
    const [rowperPage, setrowperPage] = useState(Setting.rowInPage);
    const [currpage, setcurrpage] = useState(1);

    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsOnlineForm/GetForms?rows='+rowperPage + "&page=" + page+search,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);

        }).catch(function (error) {

        });
    }

    const handlePageChange = (page :any) => {
        setcurrpage(page)
        setLoading(true);

        fetchDta(page)
    };
    const handlePerRowsChange = async  (newPerPage:any, page:any) => {
        setLoading(true);
        setrowperPage(newPerPage)
        fetchDta(page)
    }





    useEffect(() => {
        fetchDta(1)
    }, [localStorage.getItem("lang") ,search]);
    useEffect(() => {
        getCategory()
    }, [localStorage.getItem("lang") ]);


    return(

            <Container className={"my-2"} >

                    <Row>
                        <Col md={12}>
                            <div>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="1" className={"border-0"}
                                                    style={{backgroundColor: "transparent"}}>
                                        <Accordion.Header
                                            className={"d-inline-block py-0"}>{t.t("tmp.text22")}</Accordion.Header>
                                        <Accordion.Body style={{backgroundColor: "#fff"}}>
                                            <Form onSubmit={(e)=>{Search(e)}}>
                                                <Row>
                                                    <Col md={11}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Control id={"searchbyname"} placeholder={t.t("formName")} aria-label={t.t("formName")}
                                                                              style={{border: 0, borderBottom: "1px solid"}}>
                                                                </Form.Control>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Select id={"searchbycat"}
                                                                    style={{border: 0, borderBottom: "1px solid"}}>
                                                                    <option>{t.t("allForm")}</option>
                                                                    {category &&
                                                                    <>
                                                                        {
                                                                            category.map((item)=>{
                                                                                return(
                                                                                    <option key={item['id']} value={item['id']}>{item['name']}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                    }
                                                                </Form.Select>
                                                            </Col>


                                                        </Row>
                                                    </Col>
                                                    <Col md={1}>
                                                        <Button type={"submit"} className={"py-0"} aria-label={t.t("tmp.text22")}
                                                                variant={"none"}><Image
                                                            srcSet={Setting.sysUrl + "images/svg/search2.svg"}
                                                            style={{height: 40}}/></Button>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className={"my-2"}>

                                    <DataTable
                                        noDataComponent={t.t("noData")}
                                        progressPending={loading}
                                               pagination
                                        paginationPerPage={rowperPage}
                                               paginationServer
                                               paginationTotalRows={totalRows}
                                               progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                                               onChangeRowsPerPage={handlePerRowsChange}
                                               onChangePage={handlePageChange} columns={columns} data={data} />


                                </div>

                            </div>
                        </Col>
                    </Row>
             </Container>
    )
}
export default Forms
