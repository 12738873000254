import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import Box from "../boxes/boxes";
import {useEffect, useState} from "react";
import Url from "../links";
import {getUrl} from "../../helper/function";
import {useTranslation} from "react-i18next";
import {Setting} from "../../helper/setting";

function BoxesPage(prop:any){
    const t= useTranslation()
    const [children, setchildren] = useState([]);
    const [info, setinfo]:any = useState({});
    const [parent, setParent]:any = useState([]);

    useEffect(()=>{
        //console.log(prop)
        setinfo(JSON.parse(JSON.stringify(prop.data)))
        setParent(prop.data.parents)
        setchildren(prop.data.children)
    },[])

    return(
        <div className={"h-100"} style={{backgroundImage:'url('+ Setting.imagePrefix  + info.backgroundUrl +')', backgroundSize:"cover",backgroundPosition:"bottom"}}>
            <Container className={"h-100"}>


                <Row  className={"align-items-center py-3 justify-content-center h-100"}>
                    <Col xl={12} lg={12} md={12}>

                        <>
                            {parent.length > 0 &&
                            <Row className={"py-3"}>
                                <Col>
                                    <Breadcrumb dir={"rtl"} >
                                        <Breadcrumb.Item><Url key={"board0"}   href={"/"} >{t.t("homepage")}</Url></Breadcrumb.Item>
                                        {
                                            parent.slice(0).reverse().map((item:any)=>{
                                                return(
                                                    <Breadcrumb.Item><Url key={"board"+item.id}   href={getUrl(item)} >{item.name}</Url></Breadcrumb.Item>
                                                )
                                            })
                                        }
                                    </Breadcrumb>
                                </Col>
                            </Row>
                            }
                        </>

                        {info != null &&
                        <Row className={"align-items-center  justify-content-center"}>
                            {
                                children.map((item: any) => {
                                    return (
                                        <Box total={children.length} data={item} template={info.template} />
                                    )
                                })
                            }
                        </Row>
                        }
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default BoxesPage
