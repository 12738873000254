import {Col} from "react-bootstrap";

function Cols({children,total}:any){
    const num = total
    let classname = "my-2  "
    if(num == 2){
        classname += "col-md-6 col-12"
    }else if(num == 3 || num == 6){
        classname += "col-lg-4 col-sm-6 col-12"
    }else if(num == 4 || (num >= 7 && num <= 8)){
        classname += "col-lg-3 col-md-6 col-sm-6 col-12"
    }else if(num == 5 || num>=9 ) {
        classname += "col-20 col-md-4 col-sm-6 col-6"
    }
    return(
        <Col className={classname}>
            {children}
        </Col>
    )
}

export default Cols
