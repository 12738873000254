import {Col, Container, Nav, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Head from "./head";
import DirectorGeneral from "./directorGeneral";
import Member from "./member";
import Deputy from "./deputy";
import {useTranslation} from "react-i18next";
import Page from "../pages/page";

function Staff(){
    const t = useTranslation()
    const tab = React.createRef<HTMLTextAreaElement>();
    const [page,setPage] = useState("tab1")
    const openPage = (page:any) => {
        setPage(page)
    }
    useEffect(() => {
        tab.current?.click()
    }, [localStorage.getItem("lang")]);


    return(
        <Container className={"my-5 mb-5"}>
            <Row>
                <Col sm={"12"} className={"mb-4"}>
                    <Nav className={"aboutus"}>
                        <Nav.Item as={"li"}>
                            <Nav.Link eventKey="tab1" ref={tab as React.RefObject<any>} onClick={()=> openPage("tab1")} >{t.t("tmp.text66")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as={"li"}>
                            <Nav.Link eventKey="tab2" onClick={()=> openPage("tab2")} >{t.t("tmp.text67")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as={"li"}>
                            <Nav.Link eventKey="tab3" onClick={()=> openPage("tab3")}>{t.t("tmp.text68")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item  as={"li"}>
                            <Nav.Link eventKey="tab4" onClick={()=> openPage("tab4")}>{t.t("tmp.text69")}</Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Col>
                <Col sm={12}>

                    <div  >
                        {page == "tab1" &&  <Page  id={10} />}
                        {page == "tab2" &&   <Page  id={13} />}
                        {page == "tab3" &&   <Page   id={23} />}
                        {page == "tab4" &&   <Page   id={24} />}
                    </div>

                </Col>
            </Row>
        </Container>
    )
}
export default Staff
