import axios from "axios";
import {Setting} from "../helper/setting";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import Url from "./links";
import {getUrl} from "../helper/function";

function BreadCrumb(prop:any){
    const t = useTranslation()
    const [data,setdata] = useState([])
    const [children,setchildren] = useState([])
    const fetchDta = async () => {
            axios.get(Setting.api + 'PublicCmsGeneralCategory/CategoryDetialsCmsGeneralByCategoryIdAndMain?id=' + prop.id + "&mainCategory=" + prop.main,
                {
                    method: "get",
                    headers: {
                        'Accept-Language': t.i18n.language,
                        'accept': 'text/plain',
                        'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                    }
                }
            ).then(async function (response) {
                setdata(response.data)
                setchildren(response.data.parents)
            }).catch(function (error) {
            });
    };
    useEffect(() => {
        if(prop.breadcrumb != false) {
            fetchDta();
        }
    }, [localStorage.getItem("lang"),prop.id,prop.main ]);

    return(
        <>
            {children &&
            <>
                {prop.container == false ?


                    <Breadcrumb dir={"rtl"}>
                        <Breadcrumb.Item><Url key={"board0"} href={"/"}>{t.t("homepage")}</Url></Breadcrumb.Item>
                        {
                            children.slice(0).reverse().map((item: any) => {
                                return (
                                    <Breadcrumb.Item><Url key={"board" + item.id}
                                                          href={getUrl(item)}>{item.name}</Url></Breadcrumb.Item>
                                )
                            })
                        }
                    </Breadcrumb>

                    :

                <Container>
                    {children.length > 0 &&
                    <Row className={"pt-4"}>
                        <Col>
                            <Breadcrumb dir={"rtl"}>
                                <Breadcrumb.Item><Url key={"board0"} href={"/"}>{t.t("homepage")}</Url></Breadcrumb.Item>
                                {
                                    children.slice(0).reverse().map((item: any) => {
                                        return (
                                            <Breadcrumb.Item key={item.id}><Url key={item.id}
                                                                  href={getUrl(item)}>{item.name}</Url></Breadcrumb.Item>
                                        )
                                    })
                                }
                            </Breadcrumb>
                        </Col>
                    </Row>
                    }
                </Container>
            }
            </>
                }
        </>
    )
}
export default BreadCrumb
