import {Breadcrumb, Col, Container, Image, Nav, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Template1 from "../../component/pages/template1";
import axios from "axios";
import {Setting, webSetting} from "../../helper/setting";
import {useTranslation} from "react-i18next";
import Template2 from "../../component/pages/template2";
import Template3 from "../../component/pages/template3";
import Loading from "../../component/loading/loading";
import BreadCrumb from "../../component/breadcrumb";

function Page(prop:any) {
    const t=useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsPage/Get?id='+prop.id,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            document.title = webSetting.Title + " - " + response.data.title

            setLoading(false);
        }).catch(function (error) {

        });
    };

    useEffect(() => {
        fetchDta(1);
    }, [localStorage.getItem("lang"),prop.id ]);

    const info = JSON.parse(JSON.stringify(data))
    return(
        <>
            <BreadCrumb id={prop.id} main={5} breadcrumb={prop.breadcrumb} />

            {loading ?

                <Loading />:
                <Container className={"customPage my-3 "}>
                    {
                        (info.templateType == null || info.templateType <= 1) &&
                        <Template1 image={info.imageUrl} title={info.title} description={info.description} />
                    }

                    {
                        (info.templateType == 2) &&
                        <Template2 image={info.imageUrl} title={info.title} description={info.description} />
                    }
                    {
                        (info.templateType == 3) &&
                        <Template3 image={info.imageUrl} title={info.title} description={info.description} />
                    }
                </Container>
            }
        </>
    )
}

export default Page
