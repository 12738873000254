import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import Moment from "moment";
import {useTranslation} from "react-i18next";
import {Setting} from "../../helper/setting";
function EventsCol(prop:any) {
    const t = useTranslation()
    const days = [t.t("sun"),t.t("mon"),t.t("tue"),t.t("wed"),t.t("thu"),t.t("fri"),t.t("sat")]
    const month = [t.t('jan'),t.t('feb'),t.t('mar'),t.t('apr'),t.t('may'),t.t('jun'),t.t('jul'),t.t('aug'),t.t('sep'),t.t('oct'),t.t('nov'),t.t('dec')]
    return(
        <Link to={"/Event/"+prop.id}>
            <div className={"bg-white mb-3 text-center"}>
                <div className={"position-relative"}>
                    <div className={"position-absolute   me-2  top-0"}>
                        <div className={"d-flex px-1 py-2 eventdate"}><span
                            className={"month m-0 d-inline-flex"}>{month[Moment(prop.date).month()]}</span><span
                            className={"h2 bold d-inline-flex align-items-center m-0"}>{Moment(prop.date).format("DD")}</span></div>
                        <div className={"bg-white color2"} style={{fontSize: 10}}>{days[Moment(prop.date).day()]} {Moment(prop.date).format("HH:mm")}</div>
                    </div>
                    <Image srcSet={Setting.imagePrefix + prop.image} width={"100%"} alt={""}/>
                </div>
                <p className={"h5 p-3 pb-0 color2"} style={{lineHeight:1.5}}>{prop.title}</p>
                <div className={"pb-3 color2"} style={{fontSize: 14}}>


                    {days[Moment(prop.date).day()]}, {Moment(prop.date).format("DD")} {month[Moment(prop.date).month()]} {Moment(prop.date).format("YYYY")} {t.t("clock")} {Moment(prop.date).format("HH:mm")}</div>
            </div>
        </Link>
        )
}
export default EventsCol
