import {useTranslation} from "react-i18next";

export const MainCategoryId = [
    {
        apiUrl: "CmsDepartment/GetAll/GetAll?rows=300",
        withCategory: true,
        title : 'Departments',
        url : "departments",
        id : 1,
    },{
        apiUrl: "CmsCategory/GetCategorys?type=1&rows=300",
        withCategory: true,
        title : 'Articles',
        id : 2,
    },{
        apiUrl: "CmsCategory/GetCategorys?type=2&rows=300",
        withCategory: true,
        title : 'Forms',
        url: 'Forms',
        id : 3,
    },{
        apiUrl: "",
        data: [{id:1},{id:2},{id:3}],
        withCategory: true,
        title : 'Events',
        id : 4,
    },{
        apiUrl: "CmsPage/GetStaticPagess?rows=300",
        withCategory: true,
        title : 'Pages',
        url: 'page',
        id : 5,
    },{
        apiUrl: "CmsCategory/GetCategorys?type=3&rows=300",
        withCategory: true,
        id : 6,
        url: 'Protocols',
        title : 'Protocols',
    },{
        apiUrl: "CmsCategory/GetCategorys?type=4&rows=300",
        withCategory: true,
        id : 7,
        title : 'Reports',
    },{
        apiUrl: "CmsCategory/GetCategorys?type=5&rows=300",
        withCategory: true,
        id : 8,
        url: 'tenders',
        title : 'Tenders',
    },{
        apiUrl: "",
        withCategory: false,
        title : 'ContectUs',
        url: 'contactus',
        id : 9,
    },{
        apiUrl: "",
        withCategory: false,
        title : 'Phone',
        url: 'telephone',
        id : 10,
    },
];

export const MenuList = {header : [],boxes: [],robbin:[]}
export const Setting = {
    sysUrl : window.location.origin+"/",
    dateFormat : "YYYY-MM-DD",
    api : "https://smamgate.com/api/v1/", //https://cors-everywhere.herokuapp.com/
    imagePrefix : "", //https://corsanywhere.herokuapp.com/
    rowInPage : 10
}
export const GetDay = (index:any) => {
    const t = useTranslation()
    const days = [t.t("sun"),t.t("mon"),t.t("tue"),t.t("wed"),t.t("thu"),t.t("fri"),t.t("sat")]
    return days[index]
}
export const GetMonth = (index:any) => {
    const t = useTranslation()
    const month = [t.t('jan'),t.t('feb'),t.t('mar'),t.t('apr'),t.t('may'),t.t('jun'),t.t('jul'),t.t('aug'),t.t('sep'),t.t('oct'),t.t('nov'),t.t('dec')]
    return month[index]
}
export let SliderData = []

export let webSetting = {
    Title : "",
    Description: "",
    Keyword:"",
    Email:"",
    Facebook : "",
    Instagram : "",
    Whatsapp : ""
}

export const Lang = [
    {
        title : "עברית",
        dir : "rtl",
        label : "he"
    },
    {
        title:"العربية",
        dir : "rtl",
        label : "ar"
    }
];
