import {Col, Container, Image, Row} from "react-bootstrap";
import {Setting} from "../../helper/setting";
import axios from "axios";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import EventHistoryCol from "../../component/events/eventHistory";
import Loading from "../../component/loading/loading";
import PRow from "../../component/masonry";

function EventHistory(){
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchDta = async () => {
        var currentDate = ""
        var futureMonth = ""
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsEvent/GetIncomingArchived?page=1&rows=20',
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
        }).catch(function (error) {

        });
    }
    useEffect(() => {
        fetchDta()
    }, [localStorage.getItem("lang") ]);


    return(

        <Container className={"my-3"}>
            {loading ? <Loading/> :
                <PRow key={'x1'}>
                    {
                        data.map((item) => {
                            return (
                                <Col md={3} key={"col"+item['id']} className={"w-100"}>
                                    <EventHistoryCol title={item['name']} id={item['id']} date={item['timing']}
                                                     image={Setting.imagePrefix+item['imageUrl']}/>
                                </Col>
                            )
                        })
                    }
                </PRow>
            }
        </Container>
    )
}
export default EventHistory
