import {Accordion, Button, Col, Container, Form, Image, Row, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Setting} from "../../helper/setting";
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Moment from "moment";
import FsLightbox from "fslightbox-react";
import {getExtension} from "../../helper/function";
import {Video} from "react-feather";

function Protocols(){
    const fancy = useRef<FsLightbox>(null)
    const t=useTranslation()

    const [toggler,setToggler] = useState(false)
    const [url, seturl] = useState(null as any);


    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [category, setcategory] = useState([]);
    const [search,setSearch] = useState('')

    const Search = (event:any) => {
        event.preventDefault()
        let s = ''
        if(event.target.searchbyname.value != ""){
            s += '&search=' + event.target.searchbyname.value;
        }
        if(event.target.searchbydate.value != ""){
            s += '&date=' + event.target.searchbydate.value;
        }
        if(event.target.searchbycat.value != ""){
            s += '&catId=' + event.target.searchbycat.value;
        }
        setSearch(s)
    }
    const getCategory = async () => {
        axios.get(Setting.api + 'PublicCmsCategory/GetByCategory?type=3',
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setcategory(response.data);
        }).catch(function (error) {
        });
    };

    const getVideo = (row:any) => {
        let file = row.files[0].fileUrl
        seturl(null)
        if(getExtension(file) == "mp3"){
            seturl([<audio controls autoPlay>
                <source src={file} type="audio/mpeg" />
            </audio>])
        }else{
            seturl([file])
        }

        setToggler(!toggler)

    }


    const columns = [
        {
            sortable: true,
            name: t.t("tmp.text29"),
            selector: (row : any) => row.title,
        },{
            sortable: false,
            center:true,
            name: t.t("category"),
            selector: (row : any) => row.categoryName,
        },{
            sortable: false,
            center:true,
            name: t.t("tmp.text25"),
            selector: (row : any) => Moment(row.date).format(Setting.dateFormat),
        },{
            sortable: false,
            name: t.t("tmp.text30"),
            center:true,
            cell: (row : any) => <>{row.files.length >0 && <>{(getExtension(row.files[0].fileUrl).toLowerCase() === "mp4" || getExtension(row.files[0].fileUrl).toLowerCase() === "mp3") ? <Button variant={"none"} title={t.t("showVideo")} onClick={()=>{getVideo(row)}} aria-labelledby={t.t("showVideo")}  className={"m-0 p-0"} aria-label={""}><Video   /></Button>
                 :  <a href={row.files.length >0 ? Setting.imagePrefix + row.files[0].fileUrl : "#"} target={"_blank"} ><Image srcSet={Setting.sysUrl + "images/svg/folder.svg"} style={{height:35}} /></a>}</>}</>,
        }
    ]






    const [totalRows, setTotalRows] = useState(0);
    const [rowperPage, setrowperPage] = useState(Setting.rowInPage);
    const [currpage, setcurrpage] = useState(1);


    const fetchDta = async (page:any) => {
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsReportProtocol/GetReportsByType?typeId=3&page=' + page + "&rows=" + rowperPage + search,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            setLoading(false);
            setTotalRows(JSON.parse(response.headers.pagination).totalItemCount);

        }).catch(function (error) {

        });
    }
    const handlePageChange = (page :any) => {
        fetchDta(page)
    }
    const handlePerRowsChange = async  (newPerPage:any, page:any) => {
        setrowperPage(newPerPage)
        fetchDta(page)
    }

    const clearVideo = (p:FsLightbox) => {

        seturl(null)
        p.render()
    }

    useEffect(() => {
        fetchDta(1)
    }, [localStorage.getItem("lang") ,search]);

    useEffect(() => {
        getCategory()
    }, [localStorage.getItem("lang") ]);

    return(
        <Container className={"my-2"} >

            {
                url &&

                <FsLightbox  onClose={(p)=>{clearVideo(p)}}
                             toggler={toggler}  sources={url}

                />
            }


            <Row >
                <Col md={12}>
                    <div>
                        <Accordion defaultActiveKey="0"    >
                            <Accordion.Item eventKey="1" className={"border-0"} style={{backgroundColor:"transparent"}}>
                                <Accordion.Header className={"d-inline-block py-0"}>{t.t("tmp.text22")}</Accordion.Header>
                                <Accordion.Body style={{backgroundColor:"#fff"}}>

                                    <Form onSubmit={(e)=>{Search(e)}}>
                                        <Row>
                                            <Col md={11}>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Control id={"searchbyname"} style={{border:0,borderBottom:"1px solid"}} aria-label={t.t("tmp.text29")} placeholder={t.t("tmp.text29")} />
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Select id={"searchbycat"} style={{border:0,borderBottom:"1px solid"}}  aria-label={t.t("tmp.text26")}>
                                                            <option>{t.t("tmp.text41")}</option>
                                                            {
                                                                category &&

                                                                    category.map((item)=>{
                                                                        return(
                                                                            <option key={item['id']} value={item['id']}>{item['name']}</option>
                                                                        )
                                                                    })

                                                            }
                                                        </Form.Select>
                                                     </Col>
                                                    <Col md={4}>
                                                        <Form.Control id={"searchbydate"} type={"date"} style={{border:0,borderBottom:"1px solid"}} aria-label={t.t("tmp.text25")} placeholder={t.t("tmp.text25")} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={1}>
                                                <Button  type={"submit"} className={"py-0"} aria-label={t.t("tmp.text22")} variant={"none"}><Image srcSet={Setting.sysUrl + "images/svg/search2.svg"} style={{height:40}} /></Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className={"my-2 text-center"}>

                            <DataTable
                                noDataComponent={t.t("noData")}
                                progressPending={loading}
                                pagination
                                paginationPerPage={rowperPage}
                                paginationServer
                                paginationTotalRows={totalRows}
                                progressComponent ={<Spinner className={"my-5"} animation={"border"} variant={"warning"} title={"Please Wait"} />}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange} columns={columns} data={data} />


                        </div>

                    </div>
                </Col>
            </Row>
        </Container>

    )
}
export default Protocols

