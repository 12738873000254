import {Col, Container, Image, Row} from "react-bootstrap";
import {Setting} from "../../../helper/setting";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Url from "../../../component/links";


function Information(){
    const t =useTranslation()
    return(
        <div className={"h-100"}>
            <Container className={"h-100 py-4"}>
                <Row  className={"align-items-center justify-content-center h-100"}>
                    <Col md={12}>
                        <Row className={"align-items-center  "}>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Link to={ "/Page/30" } >
                                    <div className={"mbox "}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/ii1.svg"}  style={{height:120}}  /></div>
                                        <p className={"py-1 w-100 mb-1 mt-3 btn btn2  bg-gradient"}>{t.t("tmp.text19")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Link to={ "#" } >
                                    <div className={"mbox "}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/ii2.svg"} style={{height:120}}  /></div>
                                        <p className={"py-1 w-100 mb-1 mt-3 btn btn2  bg-gradient"}>{t.t("tmp.text20")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={4} xs={12} sm={12} className={"my-2"}>
                                <Url href={ "https://city4u.co.il/PortalServicesSite/cityPay/905420/mislaka/1" } target={"_blank"} >
                                    <div className={"mbox "}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/ii3.svg"}  style={{height:120}} /></div>
                                        <p className={"py-1 w-100 mb-1 mt-3 btn btn2  bg-gradient"}>{t.t("tmp.text21")}</p>
                                    </div>
                                </Url>
                            </Col>


                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Information
