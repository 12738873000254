import {Col, Container, Nav, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import News from "./news";
import EventHistory from "../events/eventhistory";
import Events from "../events/events";
import Calenders from "../events/calender";
import {useTranslation} from "react-i18next";

function EventTabs(){
    const t = useTranslation()
    const tab = React.createRef<HTMLTextAreaElement>();
    const [page,setPage] = useState("tab1")
    const openPage = (page:any) => {
        setPage(page)
    }
    useEffect(() => {
        tab.current?.click()
    }, [localStorage.getItem("lang")]);

    return(
        <Container className={"my-5 mb-5"}>
            <Row>
                <Col sm={"12"} className={"mb-4"}>
                    <Nav className={"aboutus"}>
                        <Nav.Item as={"li"}>
                            <Nav.Link eventKey="tab1" ref={tab as React.RefObject<any>} onClick={()=> openPage("tab1")} >{t.t("tmp.text74")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as={"li"}>
                            <Nav.Link eventKey="tab2" onClick={()=> openPage("tab2")} >{t.t("tmp.text75")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as={"li"}>
                            <Nav.Link eventKey="tab3" onClick={()=> openPage("tab3")}>{t.t("tmp.text76")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item  as={"li"}>
                            <Nav.Link eventKey="tab4" onClick={()=> openPage("tab4")}>{t.t("tmp.text77")}</Nav.Link>
                        </Nav.Item>

                    </Nav>
                </Col>
                <Col sm={12}>

                    <div  >
                        {page == "tab1" &&  <News />}
                        {page == "tab2" &&  <Calenders  />}
                        {page == "tab3" &&  <Events  />}
                        {page == "tab4" &&  <EventHistory />}
                    </div>

                </Col>
            </Row>
        </Container>
    )
}
export default EventTabs
