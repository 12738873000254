import {Col, Container, Image, Row} from "react-bootstrap";
import React from "react";
import {Setting} from "../helper/setting";
import {useTranslation} from "react-i18next";

function Footer() {
    const t = useTranslation()
    return(
        <footer className={"py-2"} >
            <Container>
                <Row>
                    <Col md={12} className={"text-center"}>
                        <span >  SMSM IT Solutions © 2022 </span>
                        <Image className={"px-1"} srcSet={Setting.sysUrl + "images/logos/smsm.png"} alt={""} />
                        <span  className={"mx-2 d-block d-md-inline-block"}><span className={"d-none d-md-block"}>|</span></span>
                        <Image className={"px-1"} srcSet={Setting.sysUrl + "images/logos/sector.png"} alt={""} />
                        <span className={"px-1"}>{t.t("footer")}</span>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
export default Footer;
