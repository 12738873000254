import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Setting, webSetting} from "../../helper/setting";
import {useEffect, useState} from "react";
import {Breadcrumb, Col, Container, Image, Row} from "react-bootstrap";
import {getUrl, setTitle, useTitle} from "../../helper/function";
import Loading from "../../component/loading/loading";
import Box1 from "../../component/boxes/box1";
import Box from "../../component/boxes/boxes";
import BoxesPage from "../../component/general/boxespage";
import TabsPage from "../../component/general/tabspage";
import PageType from "../../component/general/pagetype";
import Url from "../../component/links";

function Category() {
    let {catid} = useParams()
    const t=useTranslation()
    const [loading, setLoading] = useState(false);
    const [category, setcategory] = useState({});
    const [children, setchildren] = useState([]);
    const [id, setId] = useState(0);
    const [pageName, setPageName] = useState("");
    const [info, setinfo]:any = useState({});
    const [parent, setParent] = useState([]);

    const getData = async () => {
        setLoading(true)
        axios.get(Setting.api + 'PublicCmsGeneralCategory/CategoryDetialsCmsGeneralCategory?categoryId='+catid,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            document.title = webSetting.Title + " - " + response.data.name
            setinfo(JSON.parse(JSON.stringify(response.data)))
            setcategory(response.data);
            setParent(response.data.parents)
            setchildren(response.data.children)
            setLoading(false)
            setPageName(response.data.name)
            setId(response.data[0].id)

        }).catch(function (error) {
        });
    };

    useEffect(() => {
        getData()
    }, [localStorage.getItem("lang"),catid]);

    return(
        <>
        {loading ? <Loading /> :
            <>

                {info.template == 0 ?

                    <PageType data={info}  />
                    :
                    <>
                    {
                        info.template == 1 ?
                            <>
                                {category &&
                                <TabsPage data={category}/>
                                }
                            </>
                            :
                            <>
                                {category &&
                                <BoxesPage data={category}/>
                                }
                            </>
                    }
                    </>
                }
            </>
        }
        </>
    )
}
export default Category
