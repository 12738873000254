import {Col, Container, Image, Nav, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Setting} from "../../helper/setting";

function Template1(prop:any) {
    return(
        <div className={"bg-white"}>
        <Row>
            {
                (prop.image!='' && prop.image!=null) &&
                <Col xl={2} md={3} >
                    <Image srcSet={Setting.imagePrefix + prop.image} width={"100%"} />
                </Col>
            }

            <Col xl={(prop.image=='' || prop.image==null) ? 12 : 10} md={(prop.image=='' || prop.image==null) ? 12 : 9} className={"color3"} >
                <div className={"p-4"}>
                    <div className={(prop.image=='' || prop.image==null) ? " text-center " : ""} >
                        <h2 className={"normal about-title "}>{prop.title}</h2>
                    </div>

                    <div dangerouslySetInnerHTML={{__html: prop.description}}></div>
                </div>
            </Col>
        </Row>
        </div>
    )
}

export default Template1
