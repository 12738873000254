import React, {useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import axios from "axios";
import {Globe} from "react-feather";
import {Link, useLocation, useNavigate} from "react-router-dom";
import i18n from "i18next";
import App from "../App";
import {Lang, MainCategoryId} from "./setting";
import MainPage from "../main";


export const checkLang = (lang:any) => {
    const index = Lang.findIndex( (array) => {
        return array.label === lang
    });
    return index
}

export const getExtension = (filename:any) => {
    return filename.split('.').pop()
}

export function getUrl(item:any) {
    let url = ""
    if(item.typeNextUrl == 2){
        url = item.externalLink
    }else{
        if(item.mainCategoryId != 0) {
            const index = MainCategoryId.findIndex((value: any) => {
                return parseInt(value.id) == parseInt(item.mainCategoryId)
            })
            if(MainCategoryId[index].url != undefined){
                if(item.subCategoryId > 0){
                    url = '/'+MainCategoryId[index].url+"/"+item.subCategoryId+"/"+setTitle(item.name)
                }else{
                    //url = '/'+MainCategoryId[index].url+"/"+setTitle(item.name)
                    url = "/cat/"+item.id+"/"+setTitle(item.name)

                }

            }else{
                url = "/cat/"+item.id+"/"+setTitle(item.name)
            }
        }else{
            url = "/cat/"+item.id+"/"+setTitle(item.name)
        }
    }
    return url
}

export function useTitle(title:any) {
    useEffect(() => {
        const prevTitle = document.title
        document.title = title
        return () => {
            document.title = prevTitle
        }
    })
}


export function hexToRgbA(hex:any,alpha:any){
    let c:any = null;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c = hex.substring(1).split('');
        if(c.length== 3){
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+alpha+')';
    }
    return ""
}

export function UseLang(position:any) {
    const [selectedItem, setSelectedItem] = useState(Lang[0]);
    setdirection(selectedItem.dir);
    const nav = useNavigate()
    const ss = useLocation()
    //const { t, i18n } = useTranslation();

    const setLang = (index:any,click:any) => {
        const currPath = window.location.pathname.split("/");
        setSelectedItem(Lang[index])
        localStorage.setItem("dir",Lang[index].dir)
        localStorage.setItem("lang",Lang[index].label)
        localStorage.setItem("title",Lang[index].title)
        localStorage.setItem("langId",index)
        i18n.changeLanguage(Lang[index].label)
        //onsole.log(window.location.pathname.replace(currPath[1],Lang[index].label))
        //nav( window.location.pathname.replace(currPath[1],Lang[index].label),{ replace: false })
         window.location.href =window.location.pathname.replace(currPath[1].replace("/",""),Lang[index].label)
         if(click == true){
            <MainPage />
        }
    }

    useEffect(() => {
        const currPath = window.location.pathname.split("/");
        if(localStorage.getItem("langId") == null){
            //setLang(0,false)
            if(currPath[1].replace("/","") == ""){
                nav(""+Lang[0].label)
            }
        }else{
            const index = checkLang(currPath[1].replace("/",""))

            if(currPath[1].replace("/","") == ""){
                localStorage.setItem("dir",Lang[0].dir)
                localStorage.setItem("lang",Lang[0].label)
                localStorage.setItem("title",Lang[0].title)
                localStorage.setItem("langId","0")
                nav(""+Lang[0].label)

            }else{
                const index = checkLang(currPath[1].replace("/",""))
                if(index == -1){
                    localStorage.setItem("dir",Lang[0].dir)
                    localStorage.setItem("lang",Lang[0].label)
                    localStorage.setItem("title",Lang[0].title)
                    localStorage.setItem("langId","0")
                    nav(""+Lang[0].label)
                }else{
                    localStorage.setItem("dir",Lang[index].dir)
                    localStorage.setItem("lang",Lang[index].label)
                    localStorage.setItem("title",Lang[index].title)
                    localStorage.setItem("langId",String(index))
                }
            }

        }
    }, [localStorage.getItem("langId") as string]);


    return(
            <Dropdown   className="headerIcons  headerLang">
                <Dropdown.Toggle  className={"px-0 mx-0"}  variant="none" title={"change Lang"}>
                    <Globe className={"feather mx-1"} size={24} />
                    <span className="d-lg-inline-blocks visually-hidden-focusable d-none">{selectedItem?selectedItem.title:Lang[0].title}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="text-center">
                    {Object.keys(Lang).map((titles, i) => {
                        return(
                            <Dropdown.Item key={"lang"+i}   href={"/"+Lang[i].label}  onClick={()=>{setLang(i,true)}}>{Lang[i].title}</Dropdown.Item>
                         );
                    })}
                </Dropdown.Menu>
            </Dropdown>
    )
}
export const setTitle = (title : any) => {
   return encodeURIComponent(title.trim().replaceAll(" ","_"))
}

export const setdirection = (d : any) => {
    document.dir = d;
    sessionStorage.setItem("dir",d);
}
export const getdirection = () =>{
    if(sessionStorage.getItem("dir")  != null){
        setdirection(sessionStorage.getItem("dir"));
    }
}
