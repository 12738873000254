import {Col, Container, Image, Row} from "react-bootstrap";
import {Setting} from "../../../helper/setting";
import {Link} from "react-router-dom";
import {setTitle} from "../../../helper/function";
import {useTranslation} from "react-i18next";


function Informations(){
    const t = useTranslation()
    return(
        <div className={"h-100"}>
            <Container className={"h-100"}>
                <Row  className={"align-items-center justify-content-center h-100"}>
                    <Col md={12}>
                        <Row className={"align-items-center  "}>
                            <Col lg={3} xs={6} sm={6} className={"my-3"}>
                                <Link to={ "/Information" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/i1.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text18")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-3"}>
                                <Link to={ "/Protocols" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/i2.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text17")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-3"}>
                                <Link to={ "/Reports" } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/i3.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text16")}</p>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={3} xs={6} sm={6} className={"my-3"}>
                                <Link to={ "/telephone/" + setTitle(t.t("phones")) } >
                                    <div className={"mbox mbox2"}>
                                        <div><Image srcSet={Setting.sysUrl + "images/svg/i4.svg"} style={{height:75}} /></div>
                                        <p className={"pb-0 pt-0 mb-1"}>{t.t("tmp.text9")}</p>
                                    </div>
                                </Link>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Informations
