import {Col, Container, Image, Nav, Row} from "react-bootstrap";
import React from "react";
import {Setting} from "../../helper/setting";

function Template2(prop:any) {
    return(
        <Row className={"   align-items-center justify-content-center h-100"}>
            <Col lg={9} md={10}>
                <div className={""}>
                    <Row className={ prop.description.length < 1500 ? "align-items-center g-0" : "g-0" }>
                        {
                            (prop.image != '' && prop.image != null) &&
                            <Col md={4} className={  (prop.description.length < 1500 ? "" : "mt-5")  + " d-none  d-md-block"}>
                                <div className={"btn btn2 bg-gradient p-3"}>
                                    <Image srcSet={Setting.imagePrefix + prop.image} alt={""} width={"100%"}/>
                                </div>
                            </Col>
                        }
                        <Col md={(prop.image=='' || prop.image==null) ? 12 : 8}  >
                            <div className={"bg-white shadow-lg color2 my-3"}>
                                <div className={"p-4"}>

                                    <div className={"text-center my-3"}>
                                        <h1 className={" px-4 pb-2 my-3 d-inline-block  btn2 bg-gradient h3"}>{prop.title}</h1>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html: prop.description}}></div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default Template2
