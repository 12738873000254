import {Accordion, Button, Col, Container, Form, Image, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Setting} from "../../../helper/setting";

function InfoPage(){
    return(
        <div>
        <Container>
            <Row >
                <Col>
                    <div className={"bg-white shadow-lg p-3 py-5 my-5"}>
                    <Row>
                        <Col md={3}>
                            <div>
                                <div className={"btn btn2 p-3  bg-gradient"}><Image srcSet={Setting.sysUrl + "images/news/info.jpg"}  width={"100%"} /></div>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className={"color2"}>
                                <div className={"h2 mb-3 normal"}>חוק חופש המידע </div>
                                <p dir={'rtl'}>
                                    <strong>חופש מידע</strong>
                                  <br/>
                                    1.    לכל אזרח ישראלי או תושב, הזכות לקבל מידע מרשות ציבורית, בהתאם להוראות חוק זה.
                                    הגדרות
                                    <br/>
                                    2.    בחוק זה –"ועדה משותפת" – ועדה משותפת של ועדת החוקה חוק ומשפט וועדת החוץ והבטחון של הכנסת;"חברה עירונית" – חברה עירונית וחברת בת עירונית כהגדרתן בסעיף 21 לחוק יסודות התקציב, התשמ"ה-1985;
                                    <br/>
                                    "מוסד להשכלה גבוהה" – אחד מאלה:
                                    <br/>
                                    (1)   מוסד מוכר כמשמעותו בסעיף 9 לחוק המועצה להשכלה גבוהה, התשי"ח-1958 (להלן – חוק המועצה להשכלה גבוהה);
                                    <br/>
                                    (2)   מוסד שניתן לו היתר או אישור לפי סעיף 21א לחוק המועצה להשכלה גבוהה;
                                    <br/>
                                    (3)   מוסד שהתואר שהוא מעניק הוכר לפי סעיף 28א לחוק המועצה להשכלה גבוהה;
                                    <br/>
                                    "מידע" – כל מידע המצוי ברשות ציבורית, והוא כתוב, מוקלט, מוסרט, מצולם או ממוחשב;
                                    <br/>
                                    "ממונה" – מי שמונה לפי סעיף 3;
                                    <br/>
                                    "קבלת מידע" – לרבות עיון, צפיה, האזנה, העתקה, צילום, קבלת פלט מחשב או קבלת מידע בכל דרך אחרת, בהתאם לסוג המידע וצורת החזקתו;
                                    <br/>
                                    <p />
                                   <strong > "רשות ציבורית" </strong>
                                    <br/>
                                    (1)   הממשלה ומשרדי הממשלה, לרבות יחידותיהם ויחידות הסמך שלהם;
                                    <br/>
                                    (2)   לשכת נשיא המדינה;
                                    <br/>
                                    (3)   הכנסת;
                                    <br/>
                                    (4)   מבקר המדינה, למעט לגבי מידע שהגיע אליו לצורך פעולות הביקורת ובירור תלונות הציבור;
                                    <br/>
                                    (4א) ועדת הבחירות המרכזית לכנסת, למעט לגבי מידע המפורט בסעיף 79א לחוק הבחירות לכנסת [נוסח משולב], התשכ"ט-1969;
                                    <br/>
                                    (5)   בתי משפט, בתי דין, לשכות הוצאה לפועל, וגופים אחרים בעלי סמכות שפיטה על פי דין – למעט לגבי תוכנו של הליך משפטי;
                                    <br/>
                                    (6)   רשות מקומית;
                                    <br/>
                                    (7)   חברה עירונית;
                                    <br/>
                                    (8)   תאגיד שהוקם בחוק;
                                    <br/>
                                    (9)   חברה ממשלתית וחברה-בת ממשלתית כהגדרתן בחוק החברות הממשלתיות, תשל"ה-1975, למעט חברות שקבע שר המשפטים באישור ועדת החוקה חוק ומשפט של הכנסת; קביעה כאמור יכול שתהיה לגבי כל פעילותה של החברה, או לגבי פעילויות מסוימות;
                                    <br/>
                                    (9א) התאחדות ואיגוד, כאמור בסעיף 9ב לחוק הספורט, התשמ"ח-1988;
                                    <br/>
                                    (9ב) מוסד להשכלה גבוהה שהמדינה משתתפת בתקציבו בהתאם להוראות סעיף 17א לחוק המועצה להשכלה גבוהה;
                                    <br/>
                                    (9ג)  החטיבה להתיישבות, במסגרתה הפעלת סמכויותיה לפי סעיף 6ב לחוק מעמדן של ההסתדרות הציונית העולמית ושל הסוכנות היהודית לארץ ישראל, התשי"ג-1952, בכל הנוגע לניהול ענייניה הכספיים של החטיבה להתיישבות, לרבות מידע על אודות הוצאותיה, הכנסותיה, דוחותיה הכספיים, קביעת תקציבה וביצועו, וכן מידע לעניין כל מני פעוליות לפי הארוע שמתרחש הפעם לכבוד חודש רמאדן
                                </p>
                            </div>
                        </Col>
                    </Row>
                    </div>
                </Col>

            </Row>
        </Container>
        </div>
    )
}
export default InfoPage
