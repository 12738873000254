import {Carousel, Col, Container, Form, Image, InputGroup, Row} from "react-bootstrap";
import {MenuList, Setting, SliderData} from "../helper/setting";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Marquee from "react-fast-marquee";
import {PlayCircle, StopCircle} from "react-feather";
import {useTranslation} from "react-i18next";
import {getUrl, setTitle, useTitle} from "../helper/function";
import Url from "../component/links";
import axios from "axios";
import Moment from "moment";
import Department from "./department/departmentPage";
import Departments from "./department/departments";
function Home() {
    const t= useTranslation()
    const [play,setPlay] = useState(true)
    const [loadslider, setLoadSlider] = useState(false);
const nav= useNavigate()
    const [loading, setLoading] = useState(false);
    const [qnews, setqnews] = useState([]);
    const [slider, setSlider] = useState(SliderData);
    const [setting, setSetting] = useState([]);
    const [Qus, setQus] = useState([]);
    const [Ans, setAns] = useState([]);

    const [fans, setFAns] = useState(false);


    const GoTo = async (index:any) => {

        /*

         */


        setTimeout(()=>{
            if(Ans[index]['typeNextUrl'] == 1){
                if(Ans[index]['mainCategoryId'] == 0){


                    axios.get(Setting.api + 'PublicCmsGeneralCategory/CategoryDetialsCmsGeneralCategory?categoryId='+Ans[index]['subCategoryId'],
                        {
                            method: "get",
                            headers: {
                                'Accept-Language': t.i18n.language,
                                'accept': 'text/plain',
                            }
                        }
                    ).then(async function (response) {
                        setQus(response.data);
                        if(response.data.typeNextUrl == 2){
                            window.location.href = response.data.externalLink
                        }else{
                            nav("/cat/"+Ans[index]['subCategoryId']+"/"+setTitle(Ans[index]['name']))
                        }
                    }).catch(function (error) {
                    });

                }else  if(Ans[index]['mainCategoryId'] == 1){
                    nav(Ans[index]['subCategoryId'] == 0 ? ("departments/"+setTitle(Ans[index]['name'])) :  ("department/"+Ans[index]['subCategoryId']+"/"+setTitle(Ans[index]['name'])) )
                }else if(Ans[index]['mainCategoryId'] == 2){

                }else if(Ans[index]['mainCategoryId'] == 3){

                }else if(Ans[index]['mainCategoryId'] == 4){

                }else if(Ans[index]['mainCategoryId'] == 5){
                    nav("page/"+Ans[index]['subCategoryId']+"/"+Ans[index]['name'])
                }else if(Ans[index]['mainCategoryId'] == 6){

                }else if(Ans[index]['mainCategoryId'] == 7){

                }else if(Ans[index]['mainCategoryId'] == 8){

                }else if(Ans[index]['mainCategoryId'] == 9){

                }else if(Ans[index]['mainCategoryId'] == 10){

                }
            }else{
                if((Ans[index]['externalLink'] as string).includes("http://") || (Ans[index]['externalLink'] as string).includes("https://")){
                    window.location.href = Ans[index]['externalLink']
                }else{
                    nav(Ans[index]['externalLink'])
                }
            }

        },300)

    }
    const GetQustion = async () => {
        axios.get(Setting.api + 'PublicCmsPublicMainQuestion/GetQuestionsWithSub',
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                }
            }
        ).then(async function (response) {
            setQus(response.data);
        }).catch(function (error) {
        });
    }

    const setAnsData = (value:any) => {
        setAns([])
        if(value != ""){
            setAns(Qus[parseInt(value)]['subQuestions'])
        }
    }

    const fetchDta = async () => {
        var currentDate = ""
        var futureMonth = ""
        setLoading(true);
        axios.get(Setting.api + 'PublicCmsPage/GetArticals',
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                }
            }
        ).then(async function (response) {
            setqnews(response.data);
            setLoading(false);
        }).catch(function (error) {

        });
    }

    const GetSlider = async () => {
        if(SliderData.length == 0) {
            setLoadSlider(true)
            axios.get(Setting.api + 'SliderCms/Get',
                {
                    method: "get",
                    headers: {
                        'Accept-Language': t.i18n.language,
                        'accept': 'text/plain',
                    }
                }
            ).then(async function (response) {
                if (response.data.length != 0) {
                    response.data.forEach((v: never) => {
                        SliderData.push(v)
                    })
                }
                setLoadSlider(false)
                setSlider(response.data);
                //console.log("data",SliderData)
            }).catch(function (error) {

            });
        }else{
            setSlider(SliderData);
            //console.log("saved",SliderData)
        }
    }



    useEffect(() => {
         GetSlider()
    }, []);


    useEffect(() => {
        fetchDta()
        GetQustion()
    }, [localStorage.getItem("lang") ]);


    return(
        <div  className={" h-100 position-relative"}>


            <Row   className={"h-100 px-0 mx-0 align-content-between position-relative"} style={{zIndex:1}} >
                    <Col md={12}>
                        <Container>


                            <Row >
                                <Col md={"12"}>
                                    <Row className={"mt-5 mb-3 align-content-start justify-content-center"}>
                                        <Col xl={8} lg={9} md={12}>
                                            <Row className={"justify-content-center"}>
                                                <Col md={6}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id={"s1"} >{t.t("intrested")}</InputGroup.Text>
                                                        <Form.Select aria-label={t.t("intrested")} aria-describedby="s1" onChange={(v)=>{{setAnsData(v.currentTarget.value)}}}>
                                                            <option value={""}>{t.t("choose")}</option>
                                                            {Qus &&
                                                            <>
                                                                {
                                                                    Qus.map((item:any,index:any)=>{
                                                                        return(
                                                                            <option value={index}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </>

                                                            }

                                                        </Form.Select>

                                                    </InputGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text id={"s2"} >{t.t("for")}</InputGroup.Text>
                                                        <Form.Select  aria-label={t.t("for")} aria-describedby="s2" onChange={(v)=>{GoTo(v.currentTarget.value)}}>
                                                            {Ans.length > 0 &&
                                                            <>
                                                                <option selected value={""}>{t.t("choose")}</option>

                                                                {
                                                                    Ans.map((item:any,index:any)=>{
                                                                        return(
                                                                            <option selected={false} value={index}>{item.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            }
                                                        </Form.Select>

                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col md={12}>
                        <Container className={"px-0 px-lg-2"}>
                        <Row className={"align-content-center justify-content-center"}>
                            <Col xl={MenuList.boxes.length >=4  ? 11 : 0 } lg={9} md={12}>

                                <Row>
                                    {
                                        MenuList.boxes.map((item:any)=>{
                                            return(
                                                <Col md={MenuList.boxes.length >= 4 ? 3 :4} className={" my-2"} key={"box"+item.id}>
                                                    <Url className={"btn-link"} href={getUrl(item)} >
                                                        <div className={"mbox p-3"}>
                                                            <Image srcSet={Setting.imagePrefix+item.imageUrl} alt={""} />
                                                            <p className={"h4"}>{item.name}</p>
                                                            <div className={"btn-link"}   >{t.t("enter")}</div>
                                                        </div>
                                                    </Url>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Col>
                         </Row>
                        </Container>

                    </Col>
                    <Col md={"12"}>
                        <Row className={"align-content-between"} >
                            <Col md={12}>
                                <Container className={"px-0 px-lg-2"}>
                                    <Row className={"align-content-end justify-content-center"}>
                                         <Col md={"12"}>
                                            <div className={"main-icons py-3 py-md-0  my-3 mb-md-5 mb-3"}>
                                            <Row className={"align-content-center text-center justify-content-center"}>
                                                {
                                                    MenuList.robbin.map((item: any) => {
                                                        return (
                                                            <Col lg xs={4} sm={3} md={3} key={"r"+item.id} className={"mb-3 mb-lg-0"}>
                                                                <Url className={"color2"} href={getUrl(item)}>

                                                                    <div>
                                                                        <Image srcSet={Setting.imagePrefix+item.imageUrl} alt={""}/>
                                                                        <p className={"m-0"}>{item.name}</p>
                                                                    </div>
                                                                </Url>
                                                            </Col>
                                                        )
                                                    })
                                                }

                                            </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col md={"12"} className={"px-0 "}>
                                <div className={"qnews d-flex justify-content-center align-items-center  px-4  py-1"}
                                     style={{overflow: "hidden"}}>
                                    <div className={" mx-2 flex-none color1 title "}
                                         style={{width: 180}}>■ {t.t("lastestNews")}</div>
                                    <div className={" px-2 d-inline-flex text-white"} dir={"ltr"}>
                                        <Marquee gradient={false} play={play} pauseOnHover direction={"right"}
                                                 speed={70}>
                                            {
                                                qnews.map((item: any) => {
                                                    return (
                                                        <Link key={"news"+item.id}  to={"/article/"+item.id+"/"+setTitle(item.title)}><span
                                                            dir={"rtl"}>{item.title} {item.publishDate != null && " - " + Moment(item.publishDate).format(Setting.dateFormat + "HH:mm")}</span></Link>
                                                    )
                                                })
                                            }
                                        </Marquee>
                                    </div>
                                    <div className={" d-inline-flex text-white mx-2"} style={{width: 70, flex: "none"}}>
                                        {play ? <a href={"#"} aria-label={t.t("stop")} onClick={(e) => {
                                                setPlay(false)
                                            }} role={"button"}><StopCircle stroke={"#fff"} width={24} height={24}/></a> :
                                            <a href={"#"} aria-label={t.t("play")} onClick={(e) => {
                                                setPlay(true)
                                            }} role={"button"}><PlayCircle stroke={"#fff"} width={24} height={24}/></a>
                                        }
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            <Carousel className={"slider"} fade tabIndex={-1}   >
                {slider.map((item:any)=>{
                    return(
                        <Carousel.Item  className={"h-100"} style={{backgroundImage: 'url('+Setting.imagePrefix+item.fileUrl+')',backgroundSize:"cover ",backgroundRepeat:"no-repeat"}}>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
         </div>
    )
}
export default Home;
